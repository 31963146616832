<template>
  <div id="app" class="app">
    <!-- <transition name="fade"> -->
      <router-view/>
    <!-- </transition> -->
    <transition name="fade-faster">
      <AppLoadVue v-show="loadApp"/>
    </transition>
    <notifications group="foo" />
  </div>
</template>

<script>
import AppLoadVue from './components/AppLoadVue.vue'
export default {
  name: 'App',
  components: {
    AppLoadVue
  },
  computed: {
    loadApp () {
      return this.$store.getters.value('loadApp')
    }
  }
  // TODO Проточка бандажей и катков
  // TODO заменить vue-notify на что-то самописное(пол метра - много для двух уведомлений)
}
</script>

<style lang="scss">
@import "~@/assets/styles/variables.scss";
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-faster-enter-active, .fade-faster-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to, .fade-faster-enter, .fade-faster-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.app {
  font-family: 'Inter', sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.orange-text {
  color: $color-orange-logo;
  transition: color 0.3s;
}
.orange-text:hover {
  cursor: pointer;
  color: $color-orange-hover;
}


.hover-text:hover {
  transition: color 0.3s;
}
.hover-text:hover {
  color: $color-orange-hover;
}

* {
  color: $color-text;
  font-family: 'Inter', sans-serif;
}

a {
  text-decoration: none;
}

.shadow {
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.05);
}

h1 {
  width: 100%;
  color: $color-black-logo;
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 160%;
}
@media (min-width: 690px) and (max-width: 959px) {
  h1 {
    font-size: 38px;
  }
}
@media (min-width: 510px) and (max-width: 689px) {
  h1 {
    font-size: 28px;
  }
}
@media (min-width: 290px) and (max-width: 509px) {
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 160%;
  }
}

h2 {
  width: 100%;
  color: $color-black-logo;
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 160%;
}
@media (min-width: 690px) and (max-width: 959px) {
  h2 {
    font-size: 32px;
  }
}
@media (min-width: 510px) and (max-width: 689px) {
  h2 {
    font-size: 26px;
  }
}
@media (min-width: 290px) and (max-width: 509px) {
  h2 {
    font-size: 26px;
  }
}

button {
  background-color: $color-orange-logo;
  outline: none;
  width: fit-content;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;

  p {
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 18px 60px;
    // font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 106.2%;
  }
}
button:hover {
  background-color: #FF4300;
}

input {
  height: 54px;
  background-color: $color-gray-background;
  border: none;
  outline: none;
  padding: 0 25px;
  width: 285px;
  @media (min-width: 510px) and (max-width: 689px) {
    width: 245px;
  }
  @media (min-width: 290px) and (max-width: 509px) {
    width: 290px;
  }
}
input::placeholder {
  color: $color-gray-logo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 106.2%;
}

.orange-line {
  width: 6px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  /* z-index: 5; */
  background-color: $color-orange-logo;
}
body::-webkit-scrollbar, div::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
 
body::-webkit-scrollbar-track, div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
 
body::-webkit-scrollbar-thumb, div::-webkit-scrollbar-thumb {
  background-color: $color-gray-logo;
  // outline: 1px solid slategrey;
  outline: none;
  border-radius: 5px;
}

// яндекс карты
.ymaps-2-1-78-gototech { // не нужная надпись
  display: none!important;
}

.ymaps-2-1-78-zoom {
  background-color: white!important;
  border-radius: 4px!important;
  box-shadow: 0 1px 2px 1px rgba(0,0,0,.15), 0 2px 5px -3px rgba(0,0,0,.15);
}
// контролы зума
.ymaps-2-1-78-zoom__plus, .ymaps-2-1-78-zoom__minus {
  box-shadow: none!important;
  border-radius: 4px!important;
}
.ymaps-2-1-78-zoom__plus {
  .ymaps-2-1-78-zoom__icon {
    background-image: url('~@/assets/img/zoom-plus.svg')!important;
    background-size: 11px!important;
  }
}
.ymaps-2-1-78-zoom__minus {
  .ymaps-2-1-78-zoom__icon {
    background-image: url('~@/assets/img/zoom-minus.svg')!important;
    background-size: 11px!important;
  }
}
// контрол геопозиции
.ymaps-2-1-78-float-button-icon_icon_geolocation {
  background-image: url('~@/assets/img/geolocation.svg')!important;
  background-size: 14px!important;
}
</style>
