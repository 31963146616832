<template>
  <div class="category">
    <div class="header-wrapper">
      <HeaderVue/>
    </div>
    <Block1Vue :imageLink="cms.img" :bigTitle="cms.bigTitle" :viewText="cms.viewText"/>
    <ServiceListVue :listsTitle="cms.listsTitle" :lists="cms.lists" id="second" :oneItemOnly="cms.oneItemOnly" :alert="cms.alert"/>

    <div class="gallery-wrapper" v-if="cms.hasOwnProperty('gallery')">
      <div class="gallery-center">
        <div class="toggle" :class="{'toggle-small': !galleryOpen}">
          <vue-picture-swipe 
            :options="{shareEl: false}"
            :items="galleryItems"
          >
          </vue-picture-swipe>
        </div>
        <div class="toggle-blur" v-show="!galleryOpen"></div>
        <div class="toggle-button">
          <p @click="galleryOpen = !galleryOpen" class="orange-text">{{galleryOpen ? 'скрыть' : 'показать'}}</p>
        </div>
      </div>
    </div>

    <div class="shadow">
      <FeedBackVue :isServiceFeedBack="true" :title="'Не нашли то, что искали?'"/>
    </div>
    
    <Block2Vue :title="'Вам также может быть интересно'"/>
    <MapVue/>
    <FooterVue/>
  </div>
</template>

<script>
import HeaderVue from '../common/HeaderVue'
import Block1Vue from '@/components/utils/Block1Vue'
import ServiceListVue from './ServiceListVue.vue'
import FeedBackVue from '../utils/FeedBackVue'
import Block2Vue from '../main/Block2Vue'
import MapVue from '../utils/MapVue'
import FooterVue from '../common/FooterVue'

import Masonry from 'masonry-layout'

export default {
  name: 'service-main-component',
  components: {
    Block1Vue,
    HeaderVue,
    ServiceListVue,
    FeedBackVue,
    Block2Vue,
    MapVue,
    FooterVue
  },
  data () {
    return {
      serviceName: null,
      cms: null,
      galleryItems: [],
      masonry: null,
      galleryOpen: false
    }
  },
  watch: {
    $route () {
      // this.$store.dispatch('setValue', {key: 'loadApp', value: true})
      setTimeout(() => { // таймаут на смену конента под загрузочным экраном
        this.setParams()
        if (Object.prototype.hasOwnProperty.call(this.cms, 'gallery')) {
          this.galleryOpen = false
          setTimeout(() => {
            this.initGallery()
          }, 1000)
        }
        this.$store.dispatch('setValue', {key: 'loadApp', value: false})
      }, 1000)
    }
  },
  created () {
    this.setParams()
  },
  mounted () {
    if (Object.prototype.hasOwnProperty.call(this.cms, 'gallery')) {
      this.galleryOpen = false
      setTimeout(() => {
        this.initGallery()
      }, 1000)
    }
    this.$nextTick(() => {
      setTimeout(() => {
        this.$store.dispatch('setValue', {key: 'loadApp', value: false})
      }, 300)
    })
  },
  methods: {
    initGallery () {
      let gallery = document.querySelector('.my-gallery')
      let grid = gallery.querySelectorAll('img')
      let galeryClasses = ['grid-item', 'grid-item__width-2', 'grid-item__width-3', 'grid-item__width-4']
      for (let i = 0; i < grid.length; i++) {
        // if (i < 3) { // первые три нормального размера, если рандом
          grid[i].classList.add(galeryClasses[1])
          // continue
        // }
        // let rand = this.getRandomInt(-1, 100) // 0..100
        // if (rand <= 80) {
        //   grid[i].classList.add(galeryClasses[0])
        // } else {
        //   grid[i].classList.add(galeryClasses[2])
        // }

        // if (rand <= 33) {
        //   grid[i].classList.add(galeryClasses[0])
        // } else if (rand <= 66) {
        //   grid[i].classList.add(galeryClasses[1])
        // } else {
        //   grid[i].classList.add(galeryClasses[2])
        // }

        // if (rand <= 30) {
        //   grid[i].classList.add(galeryClasses[0])
        // } else if (rand <= 60) {
        //   grid[i].classList.add(galeryClasses[1])
        // } else if (rand <= 90) {
        //   grid[i].classList.add(galeryClasses[2])
        // } else {
        //   grid[i].classList.add(galeryClasses[3])
        // }
      }
      this.masonry = new Masonry(gallery, {
        itemSelector: 'img',
        // columnWidth: 300,
        columnWidth: 150,
        gutter: 2
      })
    },

    async setParams () {
      this.serviceName = this.$route.params.serviceName
      // this.cms = require(`./data/${this.serviceName}.json`)
      let parsedEntry = this.parseEntry(this.$store.getters.entry(this.serviceName))
      this.cms = parsedEntry
      if (Object.prototype.hasOwnProperty.call(this.cms, 'gallery')) {
        let imgSizes = await this.getImgSize(this.cms.gallery)
        this.galleryItems = this.cms.gallery.map((img, imgIndex) => {
          return {src: img, thumbnail: img, w: imgSizes[imgIndex].width, h: imgSizes[imgIndex].height}
          // TODO описание картинок
          // return {src: img, thumbnail: img, w: imgSizes[imgIndex].width, h: imgSizes[imgIndex].height, title: 'описание из cms'}
        })
      }
    },

    parseEntry (ent) {
      let _cms = {}
      if ('fields' in ent) {
        // block 1
        _cms.img = this.getImageUrlContentfulMix(ent.fields.img)
        _cms.bigTitle = ent.fields.bigTitle
        _cms.viewText = ent.fields.viewText

        // service list
        if ('lists2' in ent.fields && ent.fields.lists2.length) {
          _cms.listsTitle = ent.fields.listsTitle
          _cms.oneItemOnly = ent.fields.oneItemOnly
          _cms.alert = ent.fields.alert // не факт что тут
          _cms.lists = ent.fields.lists2.map(item => {
            return {
              title: item.fields.title,
              img: this.getImageUrlContentfulMix(item.fields.img),
              description: item.fields.description,
              items: item.fields.items,
              itemsTitle: item.fields.itemsTitle,
            }
          })
        } else {
          console.warn('service list is empty')
        }

        if ('gallery' in ent.fields && ent.fields.gallery.length) {
          _cms.gallery = ent.fields.gallery.map(img => {
            return this.getImageUrlContentfulMix(img)
          })
        }
      } else {
        console.warn('entry has no property fields')
      }
      return _cms
    },

    getRandomInt(min, max) {
      // return Math.floor(Math.random() * Math.floor(max));
      return Math.random() * (max - min) + min;
    },

    async getImgSize (pathArr) {
      try {
        const probe = require('probe-image-size')
        let arrSize = []
        pathArr.forEach(item => {
          arrSize.push(probe(item))
        })

        return await Promise.all(arrSize)
      } catch (error) {
        console.error('can not get images sizes', error)
        return pathArr.map(() => { return {width: 0, height: 0} })
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/styles/variables.scss";

.gallery-wrapper {
  display: flex;
  justify-content: center;
  background-color: #F7F7F7;
  .gallery-center {
    width: $global-content-width;
    position: relative;
    @media (min-width: 960px) and (max-width: 1229px) {
      width: $global-content-width-960;
    }
    @media (min-width: 690px) and (max-width: 959px) {
      width: $global-content-width-690;
    }
    @media (min-width: 510px) and (max-width: 689px) {
      width: $global-content-width-510;
    }
    @media (min-width: 290px) and (max-width: 509px) {
      width: $global-content-width-290;
    }
    .my-gallery {
      // display: flex;
      margin-top: 30px;
      margin-bottom: 50px;
      img {
        transition: all 0.3s;
        // width: 300px;
        margin-bottom: 4px;
         @media (min-width: 510px) and (max-width: 689px) {
          // width: 200px;
        }
        @media (min-width: 290px) and (max-width: 509px) {
          width: 305px;
        }
      }
    }
  }
}
.header-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 7;
}
.category {
  overflow-x: hidden;
}
.grid-item {
  width: 150px;
  // height: 150px;
}
.grid-item__width-2 {
  width: 300px;
  // height: 300px;
}
.grid-item__width-3 {
  width: 450px;
  // height: 450px;
}
.grid-item__width-4 {
  width: 600px;
  // height: 600px;
}
.toggle {
  max-height: fit-content;
  transition: all 0.3s;
}
.toggle-small {
  max-height: 250px;
  overflow: hidden;
}
.toggle-button {
  margin: 20px 0;
  p {
    text-align: center;
  }
}
.toggle-blur {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100px;
  right: 0;
  bottom: 50px;
  z-index: 5;
  background: linear-gradient(to bottom, #ffffff00, #F7F7F7);
  // background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000));
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
}
</style>
