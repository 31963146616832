<template>
  <div class="block-3">
    <div class="content">
      <h1>Сотрудничая с нами, вы получаете</h1>
      <div class="items-wrapper">
        <div class="item">
          <div class="item_number">
            <p>1</p>
            <img src="@/assets/img/quality.svg" alt="">
          </div>
          <div class="item_title">
            <p>Качество</p>
          </div>
          <div class="item_text">
            Изготавливаем продукцию из различных материалов, в том числе по чертежам и образцам заказчика
          </div>
        </div>
        <div class="item">
          <div class="item_number">
            <p>2</p>
            <img src="@/assets/img/stopwatch.svg" alt="">
          </div>
          <div class="item_title">
            <p>Скорость</p>
          </div>
          <div class="item_text">
            Располагаем более 50 единицами оборудования. У нас трудятся 30 основных и вспомогательных сотрудников
          </div>
        </div>
        <div class="item">
          <div class="item_number">
            <p>3</p>
            <img src="@/assets/img/fast-delivery.svg" alt="">
          </div>
          <div class="item_title">
            <p>Доставка</p>
          </div>
          <div class="item_text">
            Доставляем продукцию собственным транспортом и несем все гарантийные обязательства
          </div>
        </div>
        <div class="item">
          <div class="item_number">
            <p>4</p>
            <img src="@/assets/img/certificate.svg" alt="">
          </div>
          <div class="item_title">
            <p>Сертификация</p>
          </div>
          <div class="item_text">
            Имеем все разрешительные и аттестационные документы на проведение данных видов работ
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block-3'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

.block-3 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $color-gray-background;

  .content {
    width: $global-content-width;
    margin-top: 60px; // половина в блоке 2

    .items-wrapper {
      margin-top: 80px;
      height: 220px;
      display: flex;
      justify-content: space-between;
    }

    .item {
      display: flex;
      flex-direction: column;
      height: 100%;
      // justify-content: space-between;

      &_number {
        width: 100px;
        height: 50px;
        position: relative;

        p {
          font-style: normal;
          font-weight: 900;
          font-size: 100px;
          line-height: 18px;
          color: $color-light-yellow;
          opacity: 0.7;
        }

        img {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 60px;
          height: 60px;
        }
      }

      &_title {
        margin-top: 22px;
        padding-left: 30px;
        background-color: $color-light-yellow;
        height: 10px;
        width: fit-content;

        p {
          height: 18px;
          margin-top: -10px;
          font-size: 18px;
          color: $color-black-logo;
          font-style: normal;
          font-weight: bold;
          line-height: 160%;
        }
      }

      &_text {
        width: 260px;
        padding-left: 30px;
        margin-top: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  @media (min-width: 960px) and (max-width: 1229px) {
    .content {
      width: $global-content-width-960;

      .items-wrapper {
        flex-wrap: wrap;
        height: auto;
        width: 100%;
        margin-top: -30px;
      }

      .item {
        width: 50%;
        margin-top: 100px;

        &_text {
          width: 100%;
        }
      }
    }
  }
  @media (min-width: 690px) and (max-width: 959px) {
    .content {
      width: $global-content-width-690;

      .items-wrapper {
        flex-wrap: wrap;
        height: auto;
        width: 100%;
        margin-top: -30px;
      }

      .item {
        width: 50%;
        margin-top: 100px;

        &_text {
          width: 100%;
        }
      }
    }
  }
  @media (min-width: 510px) and (max-width: 689px) {
    .content {
      width: $global-content-width-510;
      margin-top: 40px;
      padding-bottom: 80px;

      .items-wrapper {
        flex-wrap: wrap;
        height: auto;
        width: 100%;
        margin-top: -30px;
      }

      .item {
        width: 50%;
        margin-top: 100px;

        &_text {
          width: 100%;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
  @media (min-width: 290px) and (max-width: 509px) {
    .content {
      width: $global-content-width-290;
      margin-top: 40px;
      padding-bottom: 80px;

      .items-wrapper {
        flex-wrap: wrap;
        height: auto;
        width: 100%;
        margin-top: -10px;
      }

      .item {
        width: 100%;
        margin-top: 75px;

        &_text {
          width: 100%;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
