<template>
  <div class="footer">
    <div class="content">

      <div class="first">
        <div class="top">
          <div class="logo">
            <router-link to="/">
              <img src="@/assets/img/logo.png" alt="">
            </router-link>
          </div>
          <div class="text">
            <p>
              © ООО Производственная компания «ТехСервис». Все права защищены
            </p>
          </div>
        </div>
        <div class="bottom">
          <!-- <p class="orange-text"> -->
            <a href="#" class="orange-text">
              Политика конфиденциальности
            </a>
          <!-- </p> -->
          <div class="underline"></div>
        </div>
      </div>

      <div class="second">
        <div class="title"><p>Услуги</p></div>
        <div class="links-wrap">
          <div class="links">
            <router-link to="/service/abz">
              <div class="item"><a>Запчасти <br>к асфальто-бетонным заводам</a></div>
            </router-link>

            <router-link to="/service/metal-processing">
              <div class="item"><a>Механическая обработка металла</a></div>
            </router-link>

            <router-link to="/service/welding-of-metal-structures">
              <div class="item"><a>Сварка <br>металлоконструкций</a></div>
            </router-link>
          </div>
          <div class="links">
            <router-link to="/service/groove-of-bandages">
              <div class="item"><a>Проточка бандажей и катков сушильного барабана</a></div>
            </router-link>

            <router-link to="/service/road-construction-machinery">
              <div class="item"><a>Запчасти к дробилкам и дорожно-строительной технике</a></div>
            </router-link>

            <router-link to="/service/other">
              <div class="item"><a>Аренда территорий и помещений</a></div>
            </router-link>

            <router-link to="/service/other">
              <div class="item"><a>Аренда оборудования</a></div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="third">
        <router-link to="/contacts">
          <div class="title"><p>Контакты</p></div>
        </router-link>
        <div class="pay">
          <div class="title">Принимаем к оплате:</div>
          <div class="pay_text">
            <p>
              <b>Безналичный расчёт.</b> Перевод <br>с Вашего банковского <br>расчетного счета.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footer-component'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

.footer {
  width: 100%;
  padding-left: 4px;
  display: flex;
  justify-content: center;
  height: 387px;
  background-color: #FFFFFF;
  @media (min-width: 690px) and (max-width: 959px) {
    height: 215px;
  }
  @media (min-width: 510px) and (max-width: 689px) {
    height: 215px;
  }
  @media (min-width: 290px) and (max-width: 509px) {
    height: 215px;
  }

  .content {
    display: flex;
    width: $global-content-width;

    .first {
      padding-top: 46px;
      width: 250px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: none;
      // background-color: rgb(139, 71, 71);

      .text {
        margin-top: 20px;
        p {
          padding-right: 5px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .bottom {
        a {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 24px;
        }
      }
      .underline {
        display: none;
      }
    }

    .second {
      padding: 70px 50px 54px 50px;
      width: 664px;
      background-color: $color-gray-background;

      .title {
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: $color-black-logo;
        }
      }

      .links-wrap {
        display: flex;
        width: 566px;
        flex: none;
        justify-content: space-between;
      }

      .links {
        height: 100%;
        display: flex;
        flex-direction: column;

        .item {
          padding-top: 20px;
          width: 250px;

          a {
            text-decoration: underline;
            text-decoration-color: #D9D9D9;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
          }

          a:hover {
            cursor: pointer;
          }
        }
      }
    }

    .third {
      padding: 70px 0 32px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: $color-black-logo;
          cursor: pointer;
        }
      }

      .pay {
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: #595959;
        }

        &_text {
          position: relative;
          p {
            font-style: normal;
            font-size: 14px;
            line-height: 19px;
            color: #ADADAD;
            position: relative;
            z-index: 2;
            b {
              font-style: normal;
              font-size: 14px;
              line-height: 19px;
              color: #ADADAD;
            }
          }
        }

        &_text::after {
          content: '';
          display: block;
          position: absolute;
          width: 46px;
          height: 46px;
          background-image: url('~@/assets/img/pay.svg');
          // margin-top: -50px;
          // margin-left: 160px;
          bottom: 0;
          right: 0;
          z-index: 1;
        }

      }
    }
  }
  @media (min-width: 960px) and (max-width: 1229px) {
    .content {
      width: $global-content-width-960;
      position: relative;

      .first {
        width: 250px;
        height: 216px;
        padding: 34px 30px 0 0;
        .underline {
          margin-top: 10px;
          display: block;
          height: 1px;
          background-color: #EAEAEA;
        }
      }

      .second {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 57px;
        .links-wrap {
          width: 494px;
        }
        .links {
          .item {
            width: 217px;
            a {
              font-size: 14px;
            }
          }
        }
      }

      .third {
        width: 146px;
        padding: 58px 30px 0 30px;
        .pay {
          position: absolute;
          left: 0;
          bottom: 32px;
        }
      }
    }
  }
  @media (min-width: 690px) and (max-width: 959px) {
    .content {
      width: $global-content-width-690;
      position: relative;

      .first {
        width: 330px;
        height: 215px;
        padding: 34px 30px 0 0;
        .underline {
          display: none;
        }
      }

      .second {
        display: none;
      }

      .third {
        height: 215px;
        // width: 146px;
        padding: 58px 30px 0 30px;

        .title {
          display: none;
        }

        .pay {
          width: 330px;
          &_text {
            p {
              width: 330px;
              margin-top: 64px;
            }
          }
          &_text::after {
            top: -64px!important;
            left: 0!important;
            right: initial;
            bottom: initial;
          }
        }
      }
    }
  }

  @media (min-width: 510px) and (max-width: 689px) {
    .content {
      width: $global-content-width-510;
      position: relative;

      .first {
        width: 240px;
        // height: 216px;
        height: auto;
        padding: 34px 30px 10px 0;
        .text {
          p {
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
          }
        }
        .underline {
          display: none;
        }
      }

      .second {
        display: none;
        padding-left: 30px;
      }

      .third {
        height: 205px;
        // width: 146px;
        padding: 58px 30px 0 30px;
        .title {
          display: none;
        }
        .pay {
          width: 330px;
          .title {
            display: block;
            margin-top: 47px;
          }
          &_text {
            p {
              width: 330px;
              margin-top: 5px;
              font-size: 14px;
              line-height: 19px;
            }
          }
          &_text::after {
            top: -99px!important;
            left: 0!important;
            right: initial;
            bottom: initial;
          }
        }
      }
    }
  }

  @media (min-width: 290px) and (max-width: 509px) {
    & {
      height: auto;
    }

    .content {
      width: $global-content-width-290;
      position: relative;
      display: flex;
      flex-direction: column;

      .first {
        width: 290px;
        // height: 216px;
        height: auto;
        padding: 38px 25px 0 25px;

        .top {
          .logo {
            display: flex;
            justify-content: center;
          }
          .text {
            p {
              text-align: center;
              font-weight: normal;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
        .underline {
          display: none;
        }
        .bottom {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      .second {
        display: none;
      }

      .third {
        height: auto;
        width: 290px;
        padding: 40px 25px;

        .title {
          display: none;
        }

        .pay {
          width: 100%;

          .title {
            display: block;
            text-align: center;
            width: 100%;
            margin-top: 64px;
          }
          &_text {
            p {
              width: 100%;
              margin-top: 0;
              text-align: center;
              br {
                display: none;
              }
            }
          }
          &_text::after {
            top: -80px!important;
            left: calc(50% - 23px)!important;
            right: initial;
            bottom: initial;
          }
        }
      }
    }
  }
}
</style>
