<template>
  <div class="partners">
    <div class="content">
      <h1>Партнёры</h1>
      <div class="text">
        <p>На сегодняшний день наша компания сотрудничает <br>более чем с 200 организациями, одними из которых являются:</p>
      </div>
      <div class="items-wrapper">
        <div class="item"><img src="@/assets/img/partner-1.svg" alt=""></div>
        <div class="item"><img src="@/assets/img/partner-2.svg" alt=""></div>
        <div class="item"><img src="@/assets/img/partner-3.svg" alt=""></div>
        <div class="item"><img src="@/assets/img/partner-4.svg" alt=""></div>
        <div class="item"><img src="@/assets/img/partner-5.svg" alt=""></div>
        <div class="item"><img src="@/assets/img/partner-6.svg" alt=""></div>
        <div class="item"><img src="@/assets/img/partner-7.svg" alt=""></div>
        <div class="item"><img src="@/assets/img/partner-8.svg" alt=""></div>
        <div class="item"><img src="@/assets/img/partner-9.svg" alt=""></div>
        <div class="item"><img src="@/assets/img/partner-10.svg" alt=""></div>
        <!-- TODO показать еще для мобилок -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'partners'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
$item-ml: 40px;

.partners {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: $color-gray-background;

  .content {
    width: calc(#{$global-content-width} + #{$item-ml});
    margin-left: -#{$item-ml};

    .text {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: center;

      p {
        width: 810px;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        br {
          display: none;
        }
      }
    }

    .items-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-top: -25px;

      .item {
        width: 180px;
        margin-left: $item-ml;
        margin-top: 105px;
      }
    }
  }
}
@media (min-width: 960px) and (max-width: 1229px) {
  .partners {
    .content {
      width: calc(#{$global-content-width-960} + 32px);
      .items-wrapper {
        .item {
          width: 160px;
          margin-left: 32px;
        }
      }
    }
  }
}
@media (min-width: 690px) and (max-width: 959px) {
  .partners {
    .content {
      width: calc(#{$global-content-width-690 + 23px});
      margin-left: -23px;
      height: 453px;
      .text {
        font-size: 16px;
        margin-left: 15px;
      }
      .items-wrapper {
        .item {
          width: 119px;
          margin-left: 23px;
        }
      }
    }
  }
}
@media (min-width: 510px) and (max-width: 689px) {
  .partners {
    .content {
      width: calc(#{$global-content-width-510 + 91px});
      margin-left: -91px;
      padding-bottom: 80px;
      .text {
        font-size: 16px;
        margin-left: 15px;
        width: auto;
        p {
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          br {
            display: inline;
          }
        }
      }
      .items-wrapper {
        .item {
          width: 180px;
          margin-left: 91px;
          margin-top: 40px;
        }
      }
    }
  }
}
@media (min-width: 290px) and (max-width: 509px) {
  .partners {
    .content {
      width: $global-content-width-290;
      margin-left: 0;
      padding-bottom: 40px;
      .text {
        font-size: 16px;
        margin-left: 15px;
        width: auto;
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          br {
            display: none;
          }
        }
      }
      .items-wrapper {
        justify-content: center;
        margin-top: 10px;

        .item {
          width: 180px;
          margin-left: 0;
          margin-top: 40px;
        }
      }
    }
  }
}
</style>