<template>
  <div class="about">
    <div class="content">
      <div class="white-block">
        <div class="orange-line"></div>
      </div>
      <h1>О компании</h1>
      <div class="text-gallery">
        <div class="text">
          <p>
            <b>Производственная Компания «Техсервис»</b> с 2011 года оказывает услуги по механической обработке металла, сварочно-сборочным и ремонтным работам.
            <br><br>
            С 2013 года одним из основных направлений в работе компании стало импортозамещение в области строительно-дорожной техники, асфальтобетонных заводов, оборудования для пищевой и нефтеперерабатывающей промышленности.
            <br><br>
            С 2014 года начато <b>производство запасных частей к АБЗ BENNINGHOVEN (МВА 160, МВА 200, МВА 3000), AMMANN, EUROMIX, запасные части к дробильным установкам BHS, EXTEC C12</b> и многим другим.
          </p>
        </div>
        <div class="gallery">
          <div class="active-image">
            <img :src="require('@/assets/img/' + images[selectedImageIndex])" alt="">
          </div>
          <div class="blur"></div>
          <div class="slider-wrapper">
            <div class="img-wrap" :class="{'img-active': selectedImageIndex === imgIndex}" @click="selectImg(imgIndex)" v-for="(img, imgIndex) in images" :key="imgIndex">
              <img :src="require('@/assets/img/' + img)" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about',
  data () {
    return {
      selectedImageIndex: 0,
      imageTimer: null,
      clickTimer: null,
      images: ['zavodik.jpg', 'zavod-blue.jpg', 'inside-factory-1.jpg', 'zavod-2.jpg', 'gallery-1.jpg', 'gallery-2.jpg', 'gallery-3.jpg'],
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.startImageSliderTimer()
    })
  },
  methods: {
    startImageSliderTimer () {
      let maxIndex = this.images.length - 1
      this.imageTimer = setInterval(() => {
        if (this.selectedImageIndex < maxIndex) {
          this.selectedImageIndex++
        } else {
          this.selectedImageIndex = 0
        }
      }, 2700)
    },

    selectImg (index) {
      this.selectedImageIndex = index
      clearInterval(this.imageTimer)
      if (this.clickTimer !== null) {
        clearTimeout(this.clickTimer)
      }
      this.clickTimer = setTimeout(() => {
        this.startImageSliderTimer()
      }, 7000)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

.about {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $color-gray-background;
  padding-bottom: 60px;

  .content {
    width: $global-content-width;
    position: relative;

    .white-block {
      position: absolute;
      height: 489px;
      width: 50vw;
      left: 702px;
      top: 117px;
      background-color: white;
    }

    .text-gallery {
      display: flex;
      width: 100%;
      padding-top: 95px;

      .text {
        width: 45%;
        padding-right: 30px;

        p {
          // font-weight: bold;
          font-size: 16px;
          line-height: 26px;
        }
      }

      .gallery {
        width: 55%;
        height: 362px;
        // background-color: blueviolet;
        display: flex;
        position: relative;
        .blur {
          position: absolute;
          pointer-events: none;
          width: 195px;
          height: 100px;
          right: 0;
          bottom: 0;
          z-index: 5;
          background: linear-gradient(to bottom, #ffffff00, #F7F7F7);
          // background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000));
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
        }

        .active-image {
          width: 495px;
          height: 360px;
          // margin-right: 15px;

          img {
            height: 100%;
          }
        }

        .slider-wrapper {
          width: 190px;
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow: auto;
          position: relative;

          .img-active {
            opacity: 1!important;
            transition: all 0.3s;
          }

          .img-wrap {
            cursor: pointer;
            transition: all 0.3s;
            opacity: 0.7;
            padding-bottom: 10px;
            width: 175px;
            height: 140px;
            img {
              width: 175px;
              height: 120px;
            }
          }
          .img-wrap:last-child {
            translate: all 0.3s;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
@media (min-width: 960px) and (max-width: 1229px) {
  .about {
    width: 100%;

    .content {
      width: $global-content-width-960;

      .white-block {
        width: 60vw;
        height: 581px;
        left: calc(50% - 40px);
        // right: 0;
      }

      .text-gallery {
        .text {
          width: 400px;
          padding-right: 0;
          flex: none;
        }
        .gallery {
          align-items: flex-end;
          flex-direction: column;
          padding-left: 31px;
          width: 100%;
          height: 477px;

          .blur {
            background: linear-gradient(to right, #ffffff00, #F7F7F7);
            bottom: 10px;
            height: 130px;
            right: -2px;
          }

          .active-image {
            width: 450px;
            height: 328px;
            img {
              height: initial;
              width: 100%;
            }
          }

          .slider-wrapper {
            margin-top: 13px;
            flex-direction: row;
            width: 450px;
            // width: 100%;
            height: 135px;
            overflow-x: auto;
            overflow-y: hidden;
            flex: none;
            // align-self: center;

            .img-wrap {
              margin-top: 8px;
              padding-bottom: 0;
              width: 169px;
              height: 125px;
              flex: none;

              img {
                width: 159px;
                height: 110px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 690px) and (max-width: 959px) {
  .about {
    width: 100%;

    .content {
      width: $global-content-width-690;

      .white-block {
        width: 60vw;
        height: 440px;
        left: calc(50% - 40px);
        top: 430px;
      }

      .text-gallery {
        display: flex;
        flex-direction: column;
        .text {
          width: 100%;
          padding-right: 0;
          flex: none;
        }
        .gallery {
          margin-top: 50px;
          width: 100%;
        }
      }
    }
  }
}
@media (min-width: 510px) and (max-width: 689px) {
  .about {
    width: 100%;

    .content {
      width: $global-content-width-510;

      .white-block {
        width: 100vw;
        height: 560px;
        left: calc(50% - 255px);
        top: 390px;
      }

      .text-gallery {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        .text {
          width: 100%;
          padding-right: 0;
          flex: none;
          p {
            font-size: 14px;
            line-height: 22px;
          }
        }
        .gallery {
          align-items: flex-end;
          flex-direction: column;
          padding-left: 0;
          width: 100%;
          height: 477px;
          margin-top: 65px;

          .blur {
            background: linear-gradient(to right, #ffffff00, #F7F7F7);
            bottom: 10px;
            height: 130px;
            right: -2px;
          }

          .active-image {
            width: 450px;
            height: 328px;
            img {
              height: initial;
              // object-fit: cover;
              height: 100%;
              width: 100%;
            }
          }

          .slider-wrapper {
            margin-top: 13px;
            flex-direction: row;
            width: 450px;
            // width: 100%;
            height: 135px;
            overflow-x: auto;
            overflow-y: hidden;
            flex: none;
            // align-self: center;

            .img-wrap {
              margin-top: 8px;
              padding-bottom: 0;
              width: 169px;
              height: 125px;
              flex: none;

              img {
                width: 159px;
                height: 110px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 290px) and (max-width: 509px) {
  .about {
    width: 100%;
    padding-bottom: 0;

    .content {
      width: $global-content-width-290;

      .white-block {
        display: none;
      }

      .text-gallery {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        .text {
          width: 100%;
          padding-right: 0;
          flex: none;
          p {
            font-size: 14px;
            line-height: 22px;
          }
        }
        .gallery {
          align-items: flex-end;
          flex-direction: column;
          padding-left: 0;
          width: 100%;
          height: 337px;
          margin-top: 65px;

          .blur {
            background: linear-gradient(to right, #ffffff00, #F7F7F7);
            bottom: 20px;
            height: 100px;
            right: -2px;
          }

          .active-image {
            width: 290px;
            height: 211px;
            img {
              height: initial;
              // object-fit: cover;
              height: 100%;
              width: 100%;
            }
          }

          .slider-wrapper {
            margin-top: 0;
            flex-direction: row;
            width: 290px;
            // width: 100%;
            height: 120px;
            overflow-x: auto;
            overflow-y: hidden;
            flex: none;
            // align-self: center;

            .img-wrap {
              margin-top: 8px;
              padding-bottom: 0;
              width: 151px;
              height: 97px;
              flex: none;

              img {
                width: 141px;
                height: 97px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
