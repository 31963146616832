<template>
  <div class="map-wrapper">
    <div id="map"></div>
    <div class="info-block">
      <div class="title">
        <p>Контакты</p>
      </div>
      <div class="addresses">
        <div class="addresses_item">
          <div class="addresses_item_adr-text" @click="flyTo('office')">
            <p>
              <b>Юридический адрес</b>: г. Воронеж,<br>ул. Полины Осипенко, 13,оф. 40
            </p>
          </div>
          <div class="shedule">
            <p>
              <b>Пн. - Пт.</b> 9:00 - 16:00<br><b>Сб. - Вс.</b> Выходной
            </p>
          </div>
        </div>
        <div class="addresses_item">
          <div class="addresses_item_adr-text" @click="flyTo('factory')">
            <p>
              <b>Адрес производства</b>: г. Воронеж,<br>ул. Красный Октябрь, 2Б
            </p>
          </div>
          <div class="shedule">
            <p>
              <b>Пн. - Пт.</b> 8:00 - 17:00<br><b>Сб. - Вс.</b> Выходной
            </p>
          </div>
        </div>
        <div class="addresses_underline"></div>
      </div>
      <div class="telephones">
        <div class="telephones_office">
          <div class="title">
            <p>Телефоны производства:</p>
          </div>
          <div class="tel"><a href="tel:+74732572951"><p class="orange-text">+7 (473) 257-29-51<span>основной</span></p></a></div>
          <div class="tel"><a href="tel:+79204198808"><p class="orange-text">+7 (920) 419-88-08</p></a></div>
        </div>
        <div class="telephones_money">
          <div class="title">
            <p>Телефон бухгалтерии:</p>
          </div>
          <div class="tel"><p class="orange-text">+7 (473) 261-27-43</p></div>
        </div>
      </div>
      <div class="last-block">
        <button @click="goToBlock('feedBackMain')"><p>обратный звонок</p></button>
        <!-- <div class="instagramm">
          <a href="https://instagram.com/tehservisvoronezh?igshid=15lfsaqna346s" target="_blank">
            <img src="@/assets/img/inst.svg" alt="">
          </a>
        </div> -->
        <div class="email">
          <p>
            <span>E-mail:</span><br><a href="mailto:tehservis-vrn@mail.ru">tehservis-vrn@mail.ru</a>
          </p>
        </div>
      </div>
      <div class="orange-line"></div>
    </div>
  </div>
</template>

<script>
import ymaps from 'ymaps'

export default {
  // TODO менять центр карты при разном разрешении
  name: 'map-component',
  data () {
    return  {
      map: null,
      mapAPI: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initMap()
    })
  },
  beforeDestroy () {
    // this.map.destroy()
    // document.getElementById('map').remove()
  },
  methods: {
    flyTo (name) {
      this.goToBlock('map', 'center')
      let windowWidth = document.documentElement.clientWidth
      setTimeout(() => {
        if (name === 'office') {
          if (windowWidth >= 960) {
            this.map.setBounds([[51.638682810245044, 39.22622460456521], [51.64669305771519, 39.24682396979958]], {
              checkZoomRange: true,
              timingFunction: 'linear',
              duration: 300
            })
          } else {
            this.map.setBounds([[51.64103419317939, 39.23776500477368], [51.64423829584818, 39.24642317547372]], {
              checkZoomRange: true,
              timingFunction: 'linear',
              duration: 300
            })
          }
        } else {
          if (windowWidth >= 960) {
            this.map.setBounds([[51.62641866244588, 39.25106218569885], [51.630305302340446, 39.26117889682436]], {
              checkZoomRange: true,
              timingFunction: 'linear',
              duration: 300
            })
          } else {
            this.map.setBounds([[51.625085268054136, 39.24993204570128], [51.63131439236751, 39.2667590872141]], {
              checkZoomRange: true,
              timingFunction: 'linear',
              duration: 300
            })
          }
        }
      }, windowWidth > 960 ? 5 : 300)
    },

    initMap () {
      console.log('init map')
      ymaps
      .load('https://api-maps.yandex.ru/2.1/?apikey=92789373-37b8-4819-9ccc-7c192f62a385&lang=ru_RU').then((maps) => {  
        this.mapAPI = maps
        this.map = new this.mapAPI.Map("map", {
            center: [51.63756842, 39.21402623], 
            zoom: 14,
            controls: ['fullscreenControl', 'typeSelector']
            // controls: ['fullscreenControl', 'geolocationControl', 'trafficControl', 'typeSelector', 'zoomControl', 'rulerControl']
          })

        // добавление меток на карту
        if (this.map !== null) {
          // console.log(maps)
          // console.log('in if')
          let markerImg = require('@/assets/img/map-icon.svg')
          let factory = new maps.Placemark([51.62676124400984, 39.26009987535331], {
            hintContent: 'Производство<br>г. Воронеж, ул. Красный Октябрь, 2Б',
            balloonContent: 'Производство<br>г. Воронеж, ул. Красный Октябрь, 2Б'
          }, {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: markerImg,
            // Размеры метки.
            iconImageSize: [30, 42],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-15, -38]
          })
          let office = new maps.Placemark([51.642715, 39.242427], {
            hintContent: 'Офис <br>г. Воронеж, ул. Полины Осипенко, 13,оф. 40',
            balloonContent: 'Офис <br>г. Воронеж, ул. Полины Осипенко, 13,оф. 40'
          }, {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: markerImg,
            // Размеры метки.
            iconImageSize: [30, 42],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-15, -38]
          })
          this.map.geoObjects.add(factory).add(office)
        }

        // добавление контролов
        this.map.controls.add(new this.mapAPI.control.ZoomControl(
          {
            options: {
              position: {
                right: 10,
                top: 230
              },
              size: 'small'
            }
          }
        ))

        this.map.controls.add(new this.mapAPI.control.GeolocationControl(
          {
            options: {
              position: {
                right: 10,
                top: 300
              }
            }
          }
        ))

        // this.map.events.add('click', (e) => {
        //   console.log(e.get('coords'))
        // })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
$info-block-left: 76px;
$info-block-right: 84px;

#map {
  width: 100vw;
  height: 600px;
}

.map-wrapper {
  padding-top: 60px;
  background-color: $color-gray-background;
  position: relative;

  .info-block {
    background-color: white;
    width: 675px;
    height: 496px;
    position: absolute;
    top: 112px; // 52макет + 60паддинг-контейнера
    // left: 242px;
    left: calc(50% - 615px);

    .title {
      margin: 50px 0 0 $info-block-left;

      p {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        height: 24px;
        line-height: 160%;
        color: $color-black-logo;
      }
    }

    .addresses_item:nth-child(1) {
      background-color: $color-gray-background;
    }
    .addresses {
      margin-top: 15px;

      &_item  {
        transition: all 0.3s;
        padding: 15px $info-block-right 17px $info-block-left;
        display: flex;
        justify-content: space-between;

        &_adr-text {
          cursor: pointer;
          p {
            font-style: normal;
            // font-weight: 600;
            font-size: 16px;
            line-height: 26px;
          }
        }

        &_shedule {
          p {
            font-style: normal;
            // font-weight: 600;
            font-size: 16px;
            line-height: 26px;
          }
        }
      }

      // &_item:hover {
      //   background-color: $color-gray-background;
      // }

      &_underline {
        width: calc(100% - #{$info-block-right} - #{$info-block-left});
        margin-left: $info-block-right;
        height: 1px;
        background-color: $color-gray-logo;
      }
    }

    .telephones {
      margin-top: 27px;
      display: flex;
      padding: 0 $info-block-right 0 $info-block-left;
      justify-content: space-between;

      .tel {
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          span {
            position: relative;
            top: -3px;
            padding-left: 5px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #7C7C7C;
          }
        }
      }

      .title {
        margin: 0;
        p {
          width: 100%;
          text-align: left;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 26px;
        }
      }
    }

    .last-block {
      margin-top: 33px;
      padding: 0 $info-block-right 0 $info-block-left;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        p {
          padding: 18px 20px;
        }
      }

      .email {
        margin-top: -3px;
        p {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 28px;
          span {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
          }
        }
      }
    }
  }
  @media (min-width: 960px) and (max-width: 1229px) {
    #map {
      width: 100vw;
      height: 100vh;
    }
    .info-block {
      width: 590px;
      height: 489px;
      left: 0;

      .title {
        margin-left: 32px;
      }
      .addresses {
        &_item {
          padding-left: 32px;
          padding-right: 43px;
        }
        &_underline {
          width: calc(100% - 32px - 43px);
          margin-left: 32px;
        }
      }
      .telephones {
        padding-left: 32px;
        padding-right: 43px;
      }
      .last-block {
        padding-left: 32px;
        padding-right: 43px;
      }
      .orange-line {
        left: 100%;
      }
    }
  }
  @media (min-width: 690px) and (max-width: 959px) {
    & {
      padding-top: 100px;
    }
    #map {
      margin-top: 540px;
      width: 100vw;
      height: 80vh;
    }
    .info-block {
      margin-top: 100px;
      top: 0;
      width: 685px;
      height: 489px;
      left: 0;
      padding-left: 60px;
      padding-right: 60px;

      .title {
        margin-left: 32px;
      }
      .addresses {
        &_item {
          padding-left: 32px;
          padding-right: 43px;
        }
        &_underline {
          width: calc(100% - 32px - 43px);
          margin-left: 32px;
        }
      }
      .telephones {
        padding-left: 32px;
        padding-right: 43px;
      }
      .last-block {
        padding-left: 32px;
        padding-right: 43px;
      }
      .orange-line {
        left: 100%;
      }
    }
  }
  @media (min-width: 510px) and (max-width: 689px) {
    & {
      padding-top: 100px;
    }
    #map {
      margin-top: 460px;
      width: 100vw;
      height: 80vh;
    }
    .info-block {
      margin-top: 70px;
      top: 0;
      width: 900px;
      height: 489px;
      left: calc(50% - 450px);

      .title {
        margin-left: 195px;
        margin-top: 40px;
      }
      .addresses {
        margin-top: 25px;
        &_item {
          padding-left: 195px;
          padding-right: 195px;
        }
        &_underline {
          width: calc(100% - 195px - 195px);
          margin-left: 195px;
        }
      }
      .telephones {
        padding-left: 195px;
        padding-right: 195px;
      }
      .last-block {
        padding-left: 195px;
        padding-right: 195px;
      }
      .orange-line {
        left: 100%;
      }
    }
  }
  @media (min-width: 290px) and (max-width: 509px) {
    & {
      padding-top: 100px;
    }
    #map {
      margin-top: 823px;
      width: 100vw;
      height: 80vh;
    }
    .info-block {
      margin-top: 70px;
      top: 0;
      width: 510px;
      height: 853px;
      left: calc(50% - 255px);

      .title {
        margin-left: 110px;
        margin-top: 40px;
      }
      .addresses_item:nth-child(1) {
        background-color: transparent;
      }
      .addresses {
        margin-top: 25px;
        &_item {
          padding-left: 110px;
          padding-right: 110px;
          display: flex;
          flex-direction: column;

          .shedule {
            margin-top: 20px;
          }
        }
        &_underline {
          width: calc(100% - 110px - 110px - 20px);
          margin-left: 120px;
          margin-top: 21px;
        }
      }
      .telephones {
        padding-left: 110px;
        padding-right: 110px;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        .telephones_money {
          margin-top: 30px;
        }
        .title {
          margin-bottom: 10px;
        }
      }
      .last-block {
        padding-left: 110px;
        padding-right: 110px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .instagramm {
          order: 1;
        }
        .email {
          order: 2;
        }
        button {
          margin-top: 44px;
          order: 3;
          margin-left: 41px;
        }
      }
      .orange-line {
        left: 100%;
      }
    }
  }
}
</style>
