import Vue from 'vue'
import Router from 'vue-router'
import MainVue from '@/components/MainVue.vue'
import ContactsVue from '@/components/contacts/ContactsVue'
import ServiceVue from '@/components/services/ServiceVue'
import store from '../storages/index'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'main',
      component: MainVue
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: ContactsVue
    },
    {
      path: '/service/:serviceName',
      name: 'service',
      component: ServiceVue
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  store.dispatch('setValue', {key: 'loadApp', value: true})
  setTimeout(() => {
    next()
  }, 10)
})

export default router