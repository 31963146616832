<template>
  <div class="main">
    <div class="header-wrapper">
      <HeaderVue/>
    </div>
    <Block1Vue/>
    <div id="services">
      <Block2Vue/>
    </div>
    <Block3Vue/>
    <Block4Vue/>
    <div id="feedBackMain">
      <FeedBackVue :title="'Связаться с нами'"/>
    </div>
    <PartnersVue/>
    <AboutVue/>
    <MapVue/>
    <FooterVue/>
  </div>
</template>

<script>
import HeaderVue from './common/HeaderVue'
import Block1Vue from './main/Block1Vue'
import Block2Vue from './main/Block2Vue'
import Block3Vue from './main/Block3Vue'
import Block4Vue from './main/Block4Vue'
import FeedBackVue from './utils/FeedBackVue'
import PartnersVue from './main/PartnersVue'
import AboutVue from './main/AboutVue'
import MapVue from './utils/MapVue'
import FooterVue from './common/FooterVue'

export default {
  name: 'main-component',
  components: {
    HeaderVue,
    Block1Vue,
    Block2Vue,
    Block3Vue,
    Block4Vue,
    FeedBackVue,
    PartnersVue,
    AboutVue,
    MapVue,
    FooterVue
  },

  data () {
    return {
      tkn: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImUzOGJjODAzNDc4ODBiZWNjMTE0NmIxNGVmMWUwMzIyMTgyNWMzY2UxMWEwNTZjZjZmODVmZWUzYzhlOTY1NTdjZTVlMjljN2JkOWViMjU1In0.eyJhdWQiOiIyIiwianRpIjoiZTM4YmM4MDM0Nzg4MGJlY2MxMTQ2YjE0ZWYxZTAzMjIxODI1YzNjZTExYTA1NmNmNmY4NWZlZTNjOGU5NjU1N2NlNWUyOWM3YmQ5ZWIyNTUiLCJpYXQiOjE2MDk4NTAwODgsIm5iZiI6MTYwOTg1MDA4OCwiZXhwIjoxNjA5ODUzNjg4LCJzdWIiOiIiLCJzY29wZXMiOltdLCJ1c2VyIjp7ImlkIjoyMDAwMDI5MzQ4LCJncm91cF9pZCI6bnVsbCwicGFyZW50X2lkIjpudWxsLCJhcmVhIjoicmVzdCJ9fQ.bkZU9HJgD2HzwLpm-nk7D9eezEn68NmNzLPi9KA4nnTs7SORe_MagiVM-q3bHuIDP7qvuieO9F856JxXHsUCZTMCeU8_YZXahmcax1x6TQWNBiTP_NuNYLimZIwEF4pBoSIwRYdt8WwVYruiU3rJ123gOkNvINzTFIp9MCke1PXGJ60caxfCH9uNPJA1ws5tyEXvmsWR8fWnrI_QCsAUcaaR_wRFwrF0kq1cCYXAhdC7rxQCfhVV5q1CoDOj-3vlnl2-owiutODZIboN40bg9YRRvXxxESoCAkTKg5-K9l9JVvkQ84oXdRij6cF0e3krGiGpxqqSpeHlaeqiWbZHTg'
    }
  },

  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$store.dispatch('setValue', {key: 'loadApp', value: false})
      }, 300)
    })
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

.main {
  width: 100%;
  overflow-x: hidden;

  .header-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 7;
  }
}
</style>
