<template>
  <div class="feedback-utils">
    <div class="left"></div>
    <div class="content" :class="{'content-service': isServiceFeedBack === true}">
      <h1>{{ title }}</h1>
      <div class="contact-info" :class="{'contact-info-service': isServiceFeedBack === true}">
        <p class="text" :class="{'text-service': isServiceFeedBack === true}">{{isServiceFeedBack !== true ? 'Для связи с нами вы можете позвонить по телефону или воспользоваться формой ниже:' : 'Возможно вам помогут наши специалисты. Позвоните по телефону или воспользуйтесь формой'}}</p>
        <div class="shedule-phone">
          <span class="shedule">8:00 - 17:00 Сб. - Вс. Выходной</span><br>
          <a href="tel:+74732572951"><span class="orange-text phone">+7 (473) 257-29-51</span></a>
        </div>
      </div>
      <div class="form" :class="{'form-service': isServiceFeedBack === true}">
        <div class="form-left">
          <input type="text" placeholder="Имя" v-model="name">
          <input type="text" placeholder="Номер телефона" class="ml20" v-model="phone" v-inputmask="'+7 (999)-999-99-99'">
          <input type="text" placeholder="Email" class="mt20" v-model="email">
          <input type="file" multiple name="files" id="files" ref="fileInput" hidden class=" input-file" @change="setFiles()" v-if="isServiceFeedBack !== true">
          <label class="mt20" for="files" v-if="popup !== true &&isServiceFeedBack !== true">
            <img src="@/assets/img/clip.svg" alt="">
            Прикрепить чертёж
          </label>
        </div>
        <div class="form-right">
          <button class="send" @click="send()"><p>Отправить</p></button>
          <div class="acception-text">
            <p>Нажимая на кнопку “Отправить”, вы соглашаетесь с <br><span class="privacy_policy">Политикой конфиденциальности</span></p>
          </div>
        </div>
      </div>
      <div class="files-wrapper">
        <div class="file" v-for="(file, index) in files" :key="'file_' + index">
          <img src="@/assets/img/file-icon.svg" alt="">
          <p>{{file.name}}</p>
          <img src="@/assets/img/close.svg" alt="" style="cursor:pointer" @click="deleteFile(index)">
        </div>
      </div>
      <div class="orange-line"></div>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'feedback-utils',
  props: ['title', 'popup', 'isServiceFeedBack'],
  data ()  {
    return {
      name: '',
      phone: '',
      email: '',
      files: []
    }
  },
  methods: {
    setFiles () {
      let _fileList = this.$refs['fileInput'].files
      _fileList.forEach(element => {
        this.files.push(element)
      })
    },

    deleteFile (index) {
      this.files.splice(index, 1)
    },

    send () {
      let url = process.env.VUE_APP_HOST + '/mail'
      let data = {
        name: this.name,
        phone: this.phone,
        email: this.email
      }
      axios.post(url, data).then(() => {
        this.$notify({
          group: 'foo',
          title: 'Сообщение отправлено',
          text: 'Мы свяжемся с Вами в ближайшее время'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

.feedback-utils {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: center;
  background-color: $color-gray-background;
  padding-bottom: 60px; // полотступа из след блока
  @media (min-width: 960px) and (max-width: 1229px) {
    width: 100%;
    justify-content: center;
  }
  @media (min-width: 690px) and (max-width: 959px) {
    width: 100%;
    justify-content: center;
  }
  @media (min-width: 510px) and (max-width: 689px) {
    width: 100%;
    justify-content: center;
  }
  @media (min-width: 290px) and (max-width: 509px) {
    width: 100%;
    justify-content: center;
  }

  .left {
    background-color: #FFFFFF;
    margin-top: 60px;
    width: calc((100% - #{$global-content-width}) / 2);
  }
  .right {
    display: none;
  }

  .content {
    justify-self: center;
    position: relative;
    width: $global-content-width;
    margin-top: 60px;
    // height: 450px;
    padding-bottom: 80px;
    padding-top: 70px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .contact-info {
      display: flex;

      .text {
        width: 440px;
        text-align: right;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
      }

      .shedule-phone {
        margin-left: 20px;

        .shedule {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 106.2%;
          color: #B0B0B0;
        }
        .phone {
          font-style: normal;
          font-weight: 900;
          font-size: 24px;
          line-height: 106.2%;
        }
      }
    }

    .form {
      margin-top: 58px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-left {
        display: flex;
        flex-wrap: wrap;
        width: 665px;
        input {
          padding: 20px;
        }
        .ml20 {
          margin-left: 20px;
        }
        .mt20 {
          margin-top: 20px;
        }
      }

      &-right {
        margin-left: 40px;
        display: flex;
        flex-direction: column;

        .acception-text {
          margin-top: 24px;

          p {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: $color-gray-logo;
            width: 197px;
          }
        }

        .privacy_policy {
          color: $color-gray-logo;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .orange-line {
      left: initial;
      right: 0;
    }
    .input-file + label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 20px;
      width: 285px;
      height: 54px;
      font-size: 16px;
      color: $color-orange-logo;
      background-color: #FFFAEE;
      padding-left: 20px;
      border: 1px dashed #FFA07E;
      @media (min-width: 510px) and (max-width: 689px) {
        width: 245px;
        top: initial;
        left: 265px;
        right: initial;
        bottom: 54px;
      }
      @media (min-width: 290px) and (max-width: 509px) {
        width: 290px;
        top: initial;
        left: 0;
        right: initial;
        bottom: 0;
      }

      img {
        margin-right: 10px;
      }
    }
    .input-file:focus + label,
    .input-file + label:hover {
      background-color: #FFF6DF;
      cursor: pointer;
      border: 1px dashed #F46532;
    }

    .files-wrapper {
      margin-right: 25px;

      .file {
        margin-top: 15px;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        height: 54px;
        width: 285px;
        padding: 0 18px 0 20px; 
        background-color: #EEFFF2;
        border: 1px solid #1FD801;

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 106.2%;
          color: #1FD801;
          padding-left: 10px;
          width: 203px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @media (min-width: 960px) and (max-width: 1229px) {
    .content {
      width: $global-content-width-960;

      .orange-line {
        display: none;
      }
    }

    .left, .right {
      display: block;
      width: 400px;
      margin-top: 60px;
      height: initial;
      background-color: #FFFFFF;
    }

  }
  @media (min-width: 690px) and (max-width: 959px) {
    .content {
      width: $global-content-width-690;
      
      .contact-info {
        .text {
          font-size: 14px;
          width: 330px;
        }
        .shedule-phone {
          .shedule {
            font-size: 15px;
          }
        }
      }

      .orange-line {
        display: none;
      }

      .form {
        flex-direction: column;

        &-left {
          margin-left: 90px;
        }

        &-right {
          margin-top: 25px;
          display: flex;
          flex-direction: row;
          height: 54px;
          margin-left: 10px;
          width: 585px;
          justify-content: flex-start;

          .send {
            width: 285px;
            flex: none;
          }

          .acception-text {
            margin-top: 12px;
            width: 343px;
            margin-left: 22px;
            flex: none;
            p {
              width: 100%;
            }
          }
        }
      }
    }

    .left, .right {
      display: block;
      width: 400px;
      margin-top: 60px;
      height: initial;
      background-color: #FFFFFF;
    }

  }
  @media (min-width: 510px) and (max-width: 689px) {
    & {
      background-color: white;
      padding-bottom: 0;
    }
    .content {
      width: $global-content-width-510;
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 47px;
      
      .contact-info {
        .text {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          width: 235px;
        }
        .shedule-phone {
          margin-top: 15px;
          .shedule {
            font-size: 14px;
          }
          .phone {
            font-weight: 900;
            font-size: 22px;
            line-height: 106.2%;
          }
        }
      }

      .orange-line {
        display: none;
      }

      .form {
        flex-direction: column;

        &-left {
          margin-left: 0;
          width: 510px;
        }

        &-right {
          // margin-top: 25px;
          display: flex;
          flex-direction: row;
          height: 54px;
          margin-left: 10px;
          width: 510px;
          justify-content: center;
          margin-left: 28px;

          .send {
            width: 245px;
            flex: none;
          }

          .acception-text {
            margin-top: 3px;
            width: 256px;
            margin-left: 30px;
            flex: none;
            p {
              font-weight: normal;
              font-size: 12px;
              line-height: 16px;
              width: 100%;
            }
          }
        }
      }
    }

    .left, .right {
      display: block;
      width: 400px;
      margin-top: 44px;
      height: initial;
      background-color: #FFFFFF;
    }

  }
  @media (min-width: 290px) and (max-width: 509px) {
    & {
      background-color: white;
      padding-bottom: 0;
    }
    .content {
      width: $global-content-width-290;
      margin-top: 0;
      padding-top: 20px;
      padding-bottom: 40px;
      
      .contact-info {
        display: flex;
        flex-direction: column;

        .text {
          margin-top: 25px;
          text-align: center;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          width: 290px;
        }
        .shedule-phone {
          margin-top: 30px;
          margin-left: 0;
          height: 40px;
          width: 290px;
          display: flex;
          flex-direction: column;
          // align-items: flex-start;
          justify-content: flex-start;

          .shedule {
            display: block;
            text-align: center;
            font-weight: normal;
            font-size: 14px;
            line-height: 50%;
          }
          .phone {
            display: block;
            text-align: center;
            font-weight: 900;
            font-size: 22px;
            line-height: 10%;
          }
        }
      }

      .orange-line {
        display: none;
      }

      .form {
        flex-direction: column;
        margin-top: 15px;

        .ml20 {
          margin-left: 0;
        }

        .mt20 {
          margin-top: 0;
        }

        &-left {
          margin-left: 0;
          width: 290px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          input {
            margin-top: 20px!important;
          }
        }

        &-right {
          // margin-top: 25px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: auto;
          margin-left: 0;
          width: 290px;

          .send {
            margin-top: 20px;
            width: 290px;
          }

          .acception-text {
            margin-top: 20px;
            width: 290px;
            margin-left: 0;
            flex: none;
            p {
              text-align: center;
              font-weight: normal;
              font-size: 12px;
              line-height: 16px;
              width: 100%;
              br {
                display: none;
              }
            }
          }
        }
      }
    }

    .left, .right {
      display: block;
      width: 400px;
      margin-top: 44px;
      height: initial;
      background-color: #FFFFFF;
    }

  }
}
// стили когда это обратный звонок в сервисах
.content-service {
  padding-bottom: 120px!important;
  @media (min-width: 290px) and (max-width: 509px) {
    padding-bottom: 60px!important;
    margin-top: 40px!important;
  }
  .send {
    @media (min-width: 510px) and (max-width: 689px) {
      width: 245px;
      margin-right: 37px;
    }
  }
}
.contact-info-service {
  @media (min-width: 510px) and (max-width: 689px) {
    margin-top: 40px;
  }
}
.form-service {
  display: block!important;
  .form-left {
    flex-wrap: nowrap!important;
    width: 100%!important;
    @media (min-width: 690px) and (max-width: 959px) {
      margin-left: 0!important;
    }
    @media (min-width: 510px) and (max-width: 689px) {
      margin-left: 0!important;
    }
  }
  .form-left input:nth-child(3) {
    margin-left: 20px!important;
    margin-top: 0!important;
    @media (min-width: 690px) and (max-width: 959px) {
      position: absolute!important;
      left: calc(50% - 314px)!important;
      top: 336px!important;
    }
    @media (min-width: 510px) and (max-width: 689px) {
      position: absolute!important;
      left: calc(50% - 275px) !important;
      top: 295px !important;
    }
    @media (min-width: 290px) and (max-width: 509px) {
      position: static!important;
      margin-left: 0!important;
      margin-top: 20px!important;
    }
  }

  .form-right {
    flex-direction: row!important;
    width: 100%!important;
    margin-left: 0!important;
    margin-top: 20px;
    @media (min-width: 290px) and (max-width: 509px) {
      flex-direction: column!important;
    }
    .send {
      height: 54px!important;
      width: 285px;
      // @media (min-width: 960px) and (max-width: 1229px) {
      //   width: 285px;
      // }
      @media (min-width: 690px) and (max-width: 959px) {
        position: absolute;
        right: calc(50% - 295px)!important;
        width: 285px!important;
        flex: none!important;
      }
      @media (min-width: 510px) and (max-width: 689px) {
        position: absolute;
        right: calc(50% - 295px)!important;
        width: 245px!important;
      }
      @media (min-width: 290px) and (max-width: 509px) {
        margin-top: 10px!important;
        margin-left: 12px;
        width: 285px!important;
      }
    }
    .acception-text {
      margin-top: 10px!important;
      margin-left: 20px!important;
      p {
        width: auto!important;
        @media (min-width: 690px) and (max-width: 959px) {
          position: absolute;
          left: calc(50% - 156px)!important;
          width: auto!important;
          text-align: center!important;
          bottom: 25px!important;
        }
        @media (min-width: 510px) and (max-width: 689px) {
          position: absolute;
          left: calc(50% - 156px)!important;
          width: auto!important;
          text-align: center!important;
          bottom: 50px!important;
        }
      }
    }
  }
}
.text-service {
  margin-top: -10px;
}
</style>
