<template>
  <div class="main">
    <div class="block-1">
      <div class="content">
        <div class="call-back">
          <button @click="goToBlock('feedBackMain')"><p>ОБРАТНЫЙ ЗВОНОК</p></button>
        </div>
        <div class="left">
          <p>
            <span class="orange-text">Механическая</span>
            обработка
            металла
          </p>
        </div>
        <div class="right">
          <div class="orange-line"></div>
          <p class="right-title">
            Производство запчастей для: 
          </p>
          <div class="right_bloks">
            <router-link to="/service/road-construction-machinery">
              <div class="right_item">
                <div class="right_item_image-wrapper">
                  <img src="@/assets/img/traktor.jpg" style="transform: scale(-1, 1)" alt="" class="right_item_image">
                </div>
                <div class="right_item_title">
                  <div class="orange-line"></div>
                  <p>Строительно-дорожной техники</p>
                </div>
              </div>
            </router-link>
            <router-link to="/service/abz">
              <div class="right_item">
                <div class="right_item_image-wrapper">
                  <img src="@/assets/img/zavod-blue.jpg" alt="" class="right_item_image">
                </div>
                <div class="right_item_title">
                  <div class="orange-line"></div>
                  <p>Асфальто-бетонных заводов</p>
                </div>
              </div>
            </router-link>
            <div class="right_item">
              <div class="right_item_image-wrapper">
                <div class="create">
                  <p>ИЗГОТОВЛЕНИЕ</p>
                </div>
                <img src="@/assets/img/yellow-babina.png" alt="" class="right_item_image">
              </div>
              <div class="right_item_title">
                <div class="orange-line"></div>
                <p>Нестандартного оборудования</p>
              </div>
            </div>
          </div>
          <div class="right_all-services">
            <p @click="goToBlock('services')">
              <span>Все услуги</span>
              <img src="@/assets/img/download-arrow.png" alt="">
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'main-component'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

.block-1 {
  width: 100vw;
  height: 100vh;
  background-image: url('~@/assets/img/background-photo.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  @media (min-width: 690px) and (max-width: 959px) {
    background-size: auto;
  }
  @media (min-width: 510px) and (max-width: 689px) {
    background-size: auto;
  }
  @media (min-width: 290px) and (max-width: 509px) {
    background-size: auto;
  }

  .content {
    width: $global-content-width;
    position: relative;
  }

  .call-back {
    position: absolute;
    left: 0;
    top: 500px;
  }

  .left {
    position: absolute;
    left: 0;
    top: 236px;
    width: 250px;

    p {
      color: $color-black-logo;
      font-style: normal;
      font-weight: 800;
      font-size: 60px;
      line-height: 68px;
    }
  }

  .right {
    position: absolute;
    left: 42.6%;
    width: 65vw;
    height: 557px;
    top: 126px;
    background-color: #f8f8f8e5;
    padding: 4.1% 0 70px 74px;
    transition: background-color 0.3s;

    &-title {
      color: #2C2C2C;
      font-size: 24px;
      line-height: 106.2%;
      font-weight: bold;
    }

    &_all-services {
      position: absolute;
      left: 80px;
      bottom: 73px;
      p {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
        transition: all 0.3s;
        span {
          color: $color-orange-logo;
          border-bottom: 1px dashed $color-orange-logo;
          transition: .3s;
        }
        img {
          height: 12px;
        }
        &:hover {
          color: $color-orange-hover;
          cursor: pointer;
        }
      }

      /* p::after {
        content: '';
        background-image: url('~@/assets/img/download-arrow.png');
        background-size: 10px 10px;
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 4px;
      } */
    }

    &_bloks {
      margin-top: 40px;
      display: flex;
      width: 625px;
      justify-content: space-between;
    }

    &_item {
      width: 195px;
      height: 305px;

      &_image-wrapper {
        position: relative;

        .create {
          background-color: $color-light-yellow;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 106px;

          p {
            text-align: center;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 19px;
          }
        }
      }

      &_image {
        width: 100%;
        height: 230px;
        object-fit: cover;
      }

      &_title {
        background-color: #FFFFFF;
        padding: 20px 20px 17px 17px;
        position: relative;

        p {
          position: relative;
          z-index: 1;
          transition: color 0.3s;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: $color-text;
        }

        .orange-line {
          width: 3px;
          transition: all 0.3s;
        }
      }
    }
  }
  .right_item:hover {
    cursor: pointer;
    .right_item_title p {
      color: white;
    }
    .orange-line {
      width: 100%;
    }
  }
  @media (min-width: 960px) and (max-width: 1229px) {
    & {
      height: 770px;
    }
    .content {
      width: $global-content-width-960;
    }
    .left {
      left: 0;
      top: 135px;
      width: 100%;

      p {
        font-size: 50px;
        text-align: center;
      }
    }
    .right {
      top: 230px;
      width: 806px;
      height: 540px;
      left: calc(50% - 403px);
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .orange-line {
        left: initial;
        top: 0;
        width: 100%;
        height: 5px;
      }

      &_item {
        .orange-line {
          left: 0;
          top: 0;
          height: 100%;
          width: 3px;
        }
      }

      &_all-services {
        left: 330px;
        top: initial;
        bottom: 45px;
      }

      &-title {
        margin-top: 46px;
      }
    }

    .call-back {
      position: absolute;
      left: 155px;
      bottom: 30px;
      top: initial;
      z-index: 5;
      button {
        p {
          padding: 18px 24px;
        }
      }
    }
  }
  @media (min-width: 690px) and (max-width: 959px) {
    & {
      height: 770px;
    }
    .content {
      width: $global-content-width-690;
    }
    .left {
      left: 0;
      top: 135px;
      width: 100%;

      p {
        font-size: 38px;
        text-align: center;
      }
    }
    .right {
      top: 230px;
      width: 690px;
      height: 540px;
      left: calc(50% - 345px);
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .orange-line {
        left: initial;
        top: 0;
        width: 100%;
        height: 5px;
      }

      &_item {
        .orange-line {
          left: 0;
          top: 0;
          height: 100%;
          width: 3px;
        }
      }

      &_all-services {
        left: 270px;
        top: initial;
        bottom: 47px;
      }

      &-title {
        margin-top: 46px;
        font-size: 22px;
      }
    }

    .call-back {
      position: absolute;
      left: 32px;
      bottom: 30px;
      top: initial;
      z-index: 5;
      button {
        p {
          padding: 18px 24px;
        }
      }
    }
  }
  @media (min-width: 510px) and (max-width: 689px) {
    & {
      height: 770px;
    }
    .content {
      width: $global-content-width-510;
    }
    .left {
      left: 0;
      top: 135px;
      width: 100%;

      p {
        font-size: 28px;
        text-align: center;
      }
    }
    .right {
      top: 230px;
      width: 510px;
      height: 540px;
      left: calc(50% - 255px);
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &_bloks {
        margin-top: 30px;
        width: 510px;
      }

      .orange-line {
        left: initial;
        top: 0;
        width: 100%;
        height: 5px;
      }

      &_item {
        width: 150px;
        height: 261px;
        .orange-line {
          left: 0;
          top: 0;
          height: 100%;
          width: 3px;
        }

        &_title {
          padding: 14px 14px 16px 16px;
          height: 84px;
          p {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      &_all-services {
        left: 270px;
        top: initial;
        bottom: 47px;
      }

      &-title {
        margin-top: 46px;
        font-size: 22px;
      }
    }

    .call-back {
      position: absolute;
      left: 32px;
      bottom: 30px;
      top: initial;
      z-index: 5;
      button {
        p {
          padding: 18px 24px;
        }
      }
    }
  }
  @media (min-width: 290px) and (max-width: 509px) {
    & {
      height: 620px;
      background-size: auto 100%;
    }
    .content {
      width: $global-content-width-290;
    }
    .left {
      left: 0;
      top: 110px;
      width: 100%;

      p {
        font-weight: 800;
        font-size: 26px;
        line-height: 28px;
        text-align: center;
      }
    }
    .right {
      top: 190px;
      width: 290px;
      height: 518px;
      left: calc(50% - 145px);
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: transparent;

      &_bloks {
        margin-top: 30px;
        width: 290px;
        display: flex;
        flex-direction: column;
      }

      .orange-line {
        display: none;
      }

      &_item {
        width: 100%;
        height: auto;
        margin-top: 20px;
        &_image-wrapper {
          display: none;
        }

        .orange-line {
          display: block;
          left: 0;
          top: 0;
          height: 100%;
          width: 3px;
        }

        &_title {
          padding: 13px 19px 15px 16px;
          height: auto;
          p {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      &_all-services {
        height: 30px;
        top: 300px;
        margin-left: 5px;
      }

      &-title {
        margin-top: 0;
        font-weight: bold;
        font-size: 18px;
        line-height: 106.2%;
      }
    }

    .call-back {
      position: absolute;
      left: 32px;
      bottom: 30px;
      top: initial;
      z-index: 5;
      button {
        p {
          padding: 18px 24px;
        }
      }
    }
  }
}
</style>
