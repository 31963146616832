<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-close" @click="closePopup()">
        <img src="@/assets/img/close-2.svg" alt="">
      </div>
      <slot/>
    </div>
    <div class="blur"></div>
  </div>
</template>

<script>
export default {
  name: 'popup-component',
  methods: {
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style scoped lang="scss">
.blur {
  background-color: rgba(73, 73, 73, 0.747);
  backdrop-filter: blur(2px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &-content {
    position: relative;
    z-index: 1;

  }
  &-close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    cursor: pointer;
  }
}
</style>
