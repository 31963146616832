<template>
  <div class="service-list">
    <div class="content">
      <div class="title"><p>{{ listsTitle }}</p></div>
      <div class="lists-wrapper">
        <div class="list" 
          :class="{'list-open': isListOpen(indexList) && oneItemOnly !== true, 'shadow': oneItemOnly !== true, 'one-item-only': oneItemOnly === true}"
          v-for="(list, indexList) in lists" :key="'list_' + indexList"
        >
          <div class="title" @click="changeVisible(indexList)" v-if="oneItemOnly !== true">
            <p>{{ list.title }}</p>
            <svg v-if="oneItemOnly !== true" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <g>
                <g>
                  <path d="M506.157,132.386c-7.803-7.819-20.465-7.831-28.285-0.029l-207.73,207.299c-7.799,7.798-20.486,7.797-28.299-0.015
                    L34.128,132.357c-7.819-7.803-20.481-7.79-28.285,0.029c-7.802,7.819-7.789,20.482,0.029,28.284l207.701,207.27
                    c11.701,11.699,27.066,17.547,42.433,17.547c15.358,0,30.719-5.846,42.405-17.533L506.128,160.67
                    C513.946,152.868,513.959,140.205,506.157,132.386z"/>
                </g>
              </g>
            </svg>
            <div class="orange-line"></div>
          </div>
          <!-- часть которая скрывается и появляется -->
          <transition name="fade-height">
            <div class="content-list" v-show="isListOpen(indexList)">
              <div class="preview-description" v-if="oneItemOnly !== true">
                <div class="img-wrapper">
                  <!-- TODO зум фото? -->
                  <!-- <img :src="('@/assets/img/' + list.img)" alt=""> -->
                  <img :src="list.img" alt="">
                </div>
                <div class="list-text">
                  <div class="list-title"><p v-html="list.description.title"></p></div>
                  <div class="list-description"><p v-html="list.description.text"></p></div>
                </div>
              </div>
              <div class="list-items">
                <div class="list-items_title"><p>{{ list['items-title'] }}</p></div>
                <!-- TODO кнопку заказать -->
                <div class="list-items_item" v-for="(item, index) in list.items" :key="'item_' + index"><span>{{ ++index + '. ' }}</span><p>{{ item }}</p></div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="alert" v-if="alert !== '' && alert !== undefined">
        <!-- <p class="alert__znak">!</p> -->
        <p class="alert__text">{{ alert }}</p>
        <div class="orange-line"></div>
      </div>
    </div>
    <!-- <transition name="fade"> -->
    <div class="callback-popup" v-if="showCallbackPopup">
      <PopupVue @closePopup="toggleCallbackPopup(false)">
        <div class="feed-wrap">
          <FeedBackCallVue :popup="true" :title="'Заявка на обратный звонок'"/>
        </div>
      </PopupVue>
    </div>
    <!-- </transition> -->
  </div>
</template>

<script>
import PopupVue from '../utils/PopupVue'
import FeedBackCallVue from '../utils/FeedBackCallVue'

export default {
  name: 'service-lists',
  props: ['listsTitle', 'lists', 'oneItemOnly', 'alert'],
  components: {
    PopupVue,
    FeedBackCallVue
  },
  data () {
    return {
      showCallbackPopup: false,
      openList: []
    }
  },
  watch: {
    lists: {
      deep: true,
      handler() { this.initList() }
    }
  },
  mounted () {
    this.initList()
  },
  methods: {
    initList () {
      // console.log('init list')
      this.openList.splice(0, this.openList.length)
      if (this.lists && this.lists.length) {
        for (let i = 0; i < this.lists.length; i++) {
          this.openList.push(this.oneItemOnly === true)
        }
      }
    },
    toggleCallbackPopup (val) {
      // TODO какой товар хотел заказать пользователь
      this.showCallbackPopup = val !== undefined ? val : !this.showCallbackPopup
    },

    isListOpen (index) {
      return this.openList[index]
    },

    changeVisible (index) {
      let val = this.openList[index]
      this.$set(this.openList, index, !val)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

.fade-height-enter-active,
.fade-height-leave-active {
  transition: .5s;
  max-height: 900px;
}
.fade-height-enter,
.fade-height-leave-to
{
  max-height: 0px;
}

.service-list {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: $color-gray-background;
  @media (min-width: 690px) and (max-width: 959px) {
    background-color: white;
  }
  @media (min-width: 510px) and (max-width: 689px) {
    background-color: white;
  }
  @media (min-width: 290px) and (max-width: 509px) {
    background-color: white;
  }

  .content {
    width: $global-content-width;
    padding-top: 120px;
    padding-bottom: 60px; // половина отсутпа до третьего блока
    @media (min-width: 960px) and (max-width: 1229px) {
      width: $global-content-width-960;
      padding-top: 60px;
      padding-bottom: 40px;
    }
    @media (min-width: 690px) and (max-width: 959px) {
      width: $global-content-width-690;
      padding-top: 60px;
      padding-bottom: 40px;
    }
    @media (min-width: 510px) and (max-width: 689px) {
      width: $global-content-width-510;
      padding-top: 60px;
      padding-bottom: 40px;
    }
    @media (min-width: 290px) and (max-width: 509px) {
      width: $global-content-width-290;
      padding-top: 60px;
      padding-bottom: 40px;
    }

    .title p {
      font-weight: 800;
      font-size: 42px;
      line-height: 160%;
      color: $color-black-logo;
      @media (min-width: 960px) and (max-width: 1229px) {
        font-weight: 800;
        font-size: 42px;
        line-height: 160%;
      }
      @media (min-width: 690px) and (max-width: 959px) {
        font-weight: 800;
        font-size: 38px;
        line-height: 44px;
      }
      @media (min-width: 510px) and (max-width: 689px) {
        font-weight: 800;
        font-size: 28px;
        line-height: 34px;
      }
      @media (min-width: 290px) and (max-width: 509px) {
        font-weight: 800;
        font-size: 22px;
        line-height: 26px;
      }
    }
  }
}

.lists-wrapper {
  margin-top: 30px;

  .list {
    margin-top: 30px;
    .title {
      position: relative;
      cursor: pointer;
      width: 100%;
      padding: 30px 36px 30px 55px;
      display: flex;
      justify-content: space-between;
      background-color: white;
      transition: .3s;
      @media (min-width: 290px) and (max-width: 509px) {
        padding: 25px 15px 30px 15px;
      }
      p {
        text-align: start;
        font-weight: bold;
        font-size: 22px;
        line-height: 106.2%;
        color: $color-black-logo;
        @media (min-width: 290px) and (max-width: 509px) {
          font-size: 18px;
        }
      }
      svg {
        width: 20px;
        fill: $color-orange-logo;
        transition: transform 0.3s;
      }
    }

    .content-list {
      background-color: white;
      overflow: hidden;
      @media (min-width: 290px) and (max-width: 509px) {
        padding-bottom: 0;
      }
      .preview-description {
        padding: 55px 36px 0 55px;
        display: flex;
        @media (min-width: 690px) and (max-width: 959px) {
          padding: 0 36px 0 30px;
        }
        @media (min-width: 510px) and (max-width: 689px) {
          padding: 0 25px 0 30px;
        }
        @media (min-width: 290px) and (max-width: 509px) {
          padding: 0 15px 0 10px;
          display: flex;
          flex-direction: column;
        }
        .img-wrapper {
          width: 155px;
          height: 155px;
          margin-right: 32px;
          flex: none;
          img {
            height: 100%;
            object-fit: cover;
          }
        }
  
        .list-text {
          .list-title p {
            font-weight: bold;
            font-size: 20px;
            line-height: 26px;
            @media (min-width: 510px) and (max-width: 689px) {
              font-weight: bold;
              font-size: 18px;
              line-height: 26px;
            }
            @media (min-width: 290px) and (max-width: 509px) {
              margin-top: 30px;
              font-weight: bold;
              font-size: 16px;
              line-height: 26px;
            }
          }
  
          .list-description {
            margin-top: 20px;
            p {
              font-weight: normal;
              font-size: 16px;
              line-height: 26px;
              @media (min-width: 510px) and (max-width: 689px) {
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
              }
              @media (min-width: 290px) and (max-width: 509px) {
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
              }
            }
          }
        }
      }

      .list-items {
        margin-top: 45px;

        &_title {
          margin-bottom: 14px;
          padding: 0 36px 0 54px;
          @media (min-width: 690px) and (max-width: 959px) {
            padding: 0 36px 0 30px;
          }
          @media (min-width: 510px) and (max-width: 689px) {
            padding: 0 36px 0 30px;
          }
          @media (min-width: 290px) and (max-width: 509px) {
            padding: 0 15px 0 10px;
          }
          p {
            font-weight: bold;
            font-size: 24px;
            line-height: 26px;
            @media (min-width: 510px) and (max-width: 689px) {
              font-size: 18px;
              line-height: 26px;
            }
            @media (min-width: 290px) and (max-width: 509px) {
              font-size: 18px;
              line-height: 26px;
            }
          }
        }
        &_item {
          padding: 22px 36px 22px 54px;
          transition: box-shadow 0.3s;
          @media (min-width: 690px) and (max-width: 959px) {
            padding: 20px 36px 20px 30px;
          }
          @media (min-width: 510px) and (max-width: 689px) {
            padding: 20px 36px 20px 30px;
          }
          @media (min-width: 290px) and (max-width: 509px) {
            padding: 20px 20px 20px 10px;
          }
          p {
            font-weight: 500;
            font-size: 20px;
            line-height: 106.2%;
            @media (min-width: 510px) and (max-width: 689px) {
              font-weight: 500;
              font-size: 18px;
              line-height: 106.2%;
            }
            @media (min-width: 290px) and (max-width: 509px) {
              font-weight: 500;
              font-size: 16px;
              line-height: 106.2%;
            }

          }
          span {
            float: left;
            color: $color-gray-logo;
            padding-right: 12px;
          }
        }
        &_item:nth-child(2n + 1) {
          background-color: #F7F7F7;
        }
        &_item:hover {
          box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  .list-open {
    .title {
      background-color: #FFE7A8;
      svg {
        transform: rotate(-180deg);
      }
    }
  }
}

.alert {
  background-color: white;
  padding: 17px 0 17px 50px;
  position: relative;
  margin-top: 50px;
  width: 100vw;
  @media (min-width: 690px) and (max-width: 959px) {
    padding: 12px 0 15px 30px;
  }
  @media (min-width: 510px) and (max-width: 689px) {
    padding: 12px 10px 15px 30px;
  }
  @media (min-width: 290px) and (max-width: 509px) {
    padding: 12px 0 15px 30px;
  }

  &__text {
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    line-height: 110%;
    @media (min-width: 510px) and (max-width: 689px) {
      font-size: 18px;
    }
    @media (min-width: 290px) and (max-width: 509px) {
      font-size: 18px;
    }
  }
}
.alert__text::before {
  content: '!';
  padding-right: 15px;
  color: #F46532;
  font-size: 36px;
  position: relative;
  top: 5px;
}

.one-item-only {
  .title {
    background-color: transparent!important;
  }
  .content-list {
    background-color: transparent!important;
    padding-top: 10px!important;
  }
  .orange-line {
    display: none;
  }
  .list-items_item:nth-child(odd) {
    background-color: white!important;
  }
}
</style>
