export default {
  methods: {
    goToBlock (services, position) {
      let block = document.getElementById(services)
      if (block === null || block === undefined) {
        return
      }
      block.scrollIntoView({
        behavior: 'smooth',
        block: position !== undefined ? position : 'start'
      })
    },

    getImageUrlContentfulMix (imgObj) {
      try {
        if (imgObj && 'fields' in imgObj && 'file' in imgObj.fields && 'url' in imgObj.fields.file) {
          return imgObj.fields.file.url
        } else {
          return ''
        }
      } catch (error) {
        console.warn('fail get img link', error)
        return ''
      }
    }
  }
}