<template>
  <div class="map-wrapper">
    <div class="content" id="second">
      <div class="title"><p>Адреса офиса <br>и производства</p></div>
      <div class="info-wite-block">
        <div class="orange-line"></div>
      </div>
      <div class="info">
        <div class="info-irl">
          <div class="left">
            <div class="title">
              <p @click="flyTo('office')">Юридический адрес: 
                <span class="on-map orange-text" @click="flyTo('office')">
                  На карте↓
                </span>
                <span class="on-map-mini" @click="flyTo('office')">
                  <img src="@/assets/img/show-on-map.svg" alt="">
                </span>
              </p>
            </div>
            <div class="address" @click="flyTo('office')"><p>г. Воронеж, ул. Полины<br> Осипенко, 13, оф. 40</p></div>
            <div class="schedule">
              <p><b>Пн. - Пт.</b> 9:00 - 16:00 <br><b>Сб. - Вс.</b> Выходной</p>
            </div>
            <div class="tel">
              <div class="tel-title"><p>Телефон бухгалтерии:</p></div>
              <div class="tel-number"><a href="tel:+74732612743"><p class="orange-text">+7 (473) 261-27-43</p></a></div>
            </div>
          </div>
          <div class="right">
            <div class="title">
              <p @click="flyTo('factory')">Адрес производства: 
                <span class="on-map orange-text" @click="flyTo('factory')">
                  На карте↓
                </span>
                <span class="on-map-mini" @click="flyTo('factory')">
                  <img src="@/assets/img/show-on-map.svg" alt="">
                </span>
              </p>
            </div>
            <div class="address" @click="flyTo('factory')"><p>г. Воронеж, ул. Красный<br> Октябрь, 2Б</p></div>
            <div class="schedule">
              <p><b>Пн. - Пт.</b> 8:00 - 17:00 <br><b>Сб. - Вс.</b> Выходной</p>
            </div>
            <div class="tel">
              <div class="tel-title"><p>Телефоны производства: </p></div>
              <div class="tel-number">
                <p class="orange-text">
                  <a href="tel:+74732572951" class="orange-text">+7 (473) 257-29-51</a><span>основной</span><br>
                  <a href="tel:+79204198808" class="orange-text">+7 (920) 419-88-08</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="gray-line"></div>
        <div class="info-online">
          <div class="email">
            <div class="email-title">
              <p>E-mail:</p>
            </div>
            <div class="email-text">
              <a href="mailto:tehservis-vrn@mail.ru">
                tehservis-vrn@mail.ru
              </a>
            </div>
          </div>
          <!-- <div class="inst">
            <div class="inst-text"><p>Подписывайтесь <br>на наш Instagram: </p></div>
            <div class="inst-img">
              <a href="https://instagram.com/tehservisvoronezh?igshid=15lfsaqna346s" target="_blank">
                <img src="@/assets/img/inst.svg" alt="">
              </a>
            </div>
          </div> -->
        </div>
      </div>
      <div id="map"></div>
    </div>
  </div>
</template>

<script>
import ymaps from 'ymaps'

export default {
  name: 'map-component-contacts',
  data () {
    return  {
      map: null,
      mapAPI: null
    }
  },
  beforeDestroy () {
    // this.map.destroy()
    // document.getElementById('map').remove()
  },
  methods: {
    flyTo (name) {
      this.goToBlock('map', 'center')
      let windowWidth = document.documentElement.clientWidth
      setTimeout(() => {
        if (name === 'office') {
          this.map.setBounds([[51.64103419317939, 39.23776500477368], [51.64423829584818, 39.24642317547372]], {
            checkZoomRange: true,
            timingFunction: 'linear',
            duration: 300
          })
        } else {
          this.map.setBounds([[51.62641866244588, 39.25106218569885], [51.630305302340446, 39.26117889682436]], {
            checkZoomRange: true,
            timingFunction: 'linear',
            duration: 300
          })
        }
      }, windowWidth > 960 ? 5 : 300)
    }
  },
  mounted () {
    ymaps
    .load('https://api-maps.yandex.ru/2.1/?apikey=92789373-37b8-4819-9ccc-7c192f62a385&lang=ru_RU').then((maps) => {  
      this.mapAPI = maps
      this.map = new this.mapAPI.Map("map", {
          center: [51.63623320748356, 39.24861599745604], 
          zoom: 13,
          controls: ['fullscreenControl', 'typeSelector']
          // controls: ['fullscreenControl', 'geolocationControl', 'trafficControl', 'typeSelector', 'zoomControl', 'rulerControl']
        })

      // добавление меток на карту
      if (this.map !== null) {
        // console.log(maps)
        // console.log('in if')
        let markerImg = require('@/assets/img/map-icon.svg')
        let factory = new maps.Placemark([51.62676124400984, 39.26009987535331], {
          hintContent: 'Производство<br>г. Воронеж, ул. Красный Октябрь, 2Б',
          balloonContent: 'Производство<br>г. Воронеж, ул. Красный Октябрь, 2Б'
        }, {
          // Опции.
          // Необходимо указать данный тип макета.
          iconLayout: 'default#image',
          // Своё изображение иконки метки.
          iconImageHref: markerImg,
          // Размеры метки.
          iconImageSize: [30, 42],
          // Смещение левого верхнего угла иконки относительно
          // её "ножки" (точки привязки).
          iconImageOffset: [-15, -38]
        })
        let office = new maps.Placemark([51.642715, 39.242427], {
          hintContent: 'Офис <br>г. Воронеж, ул. Полины Осипенко, 13,оф. 40',
          balloonContent: 'Офис <br>г. Воронеж, ул. Полины Осипенко, 13,оф. 40'
        }, {
          // Опции.
          // Необходимо указать данный тип макета.
          iconLayout: 'default#image',
          // Своё изображение иконки метки.
          iconImageHref: markerImg,
          // Размеры метки.
          iconImageSize: [30, 42],
          // Смещение левого верхнего угла иконки относительно
          // её "ножки" (точки привязки).
          iconImageOffset: [-15, -38]
        })
        this.map.geoObjects.add(factory).add(office)
      }

      // добавление контролов
      this.map.controls.add(new this.mapAPI.control.ZoomControl(
        {
          options: {
            position: {
              right: 10,
              top: 230
            },
            size: 'small'
          }
        }
      ))

      this.map.controls.add(new this.mapAPI.control.GeolocationControl(
        {
          options: {
            position: {
              right: 10,
              top: 300
            }
          }
        }
      ))
    })
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
$info-block-left: 76px;
$info-block-right: 84px;

#map {
  position: absolute;
  right: 0;
  top: 295px;
  width: 50%;
  height: 430px;
  @media (min-width: 960px) and (max-width: 1229px) {
    width: 40%;
    top: 250px;
  }
  @media (min-width: 690px) and (max-width: 959px) {
    right: initial;
    left: 0;
    width: 100vw;
    top: 750px;
    height: 369px;
  }
  @media (min-width: 510px) and (max-width: 689px) {
    right: initial;
    left: 0;
    width: 100vw;
    top: 750px;
    height: 369px;
  }
  @media (min-width: 290px) and (max-width: 509px) {
    right: initial;
    left: 0;
    width: 100vw;
    top: 950px;
    height: 369px;
  }
}

.map-wrapper {
  width: 100%;
  // height: 100%;
  height: 910px;
  display: flex;
  justify-content: center;
  background-color: $color-gray-background;
  position: relative;
  @media (min-width: 690px) and (max-width: 959px) {
    height: 1200px;
  }
  @media (min-width: 510px) and (max-width: 689px) {
    height: 1200px;
  }
  @media (min-width: 290px) and (max-width: 509px) {
    height: 1370px;
  }

  .content {
    width: $global-content-width;
    padding-bottom: 120px; // половина отсутпа до третьего блока
    padding-top: 60px;
    @media (min-width: 290px) and (max-width: 509px) {
      padding-top: 25px;
    }

    .title {
      width: 100%;
      margin-top: 40px;
      @media (min-width: 290px) and (max-width: 509px) {
        display: flex;
        justify-content: center;
      }
      p {
        font-weight: 800;
        font-size: 42px;
        line-height: 160%;
        @media (min-width: 690px) and (max-width: 959px) {
          font-size: 38px;
          // text-align: center;
        }
        @media (min-width: 510px) and (max-width: 689px) {
          font-size: 28px;
          // text-align: center;
        }
        @media (min-width: 290px) and (max-width: 509px) {
          width: 290px;
          font-size: 22px;
          // text-align: center;
        }
        br {
          display: none;
          @media (min-width: 290px) and (max-width: 509px) {
            display: block;
          }
        }
        .on-map {
          display: none;
          @media (min-width: 690px) and (max-width: 959px) {
            display: inline-block;
            font-weight: bold;
            font-size: 13px;
            line-height: 106.2%;
            text-transform: uppercase;
          }
          @media (min-width: 510px) and (max-width: 689px) {
            display: none;
          }
          @media (min-width: 290px) and (max-width: 509px) {
            display: none;
          }
        }
        .on-map-mini {
          display: none;
          @media (min-width: 510px) and (max-width: 689px) {
            display: inline-block;
          }
          @media (min-width: 290px) and (max-width: 509px) {
            display: inline-block;
          }
        }
      }
    }

    .info-wite-block {
      position: absolute;
      top: 210px;
      right: calc(50% - 400px);
      width: 100vw;
      height: 630px;
      z-index: 0;
      background-color: #F8F8F8;
      opacity: 0.9;
      box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.05);
      @media (min-width: 960px) and (max-width: 1229px) {
        height: 560px;
      }
      @media (min-width: 690px) and (max-width: 959px) {
        height: 540px;
        left: 0;
      }
      @media (min-width: 510px) and (max-width: 689px) {
        height: 540px;
        left: 0;
      }
      @media (min-width: 290px) and (max-width: 509px) {
        display: none;
      }

      .orange-line {
        left: initial;
        right: 0;
        @media (min-width: 690px) and (max-width: 959px) {
          left: 0;
          height: 5px;
          width: 100%;
          top: initial;
          bottom: 0;
        }
        @media (min-width: 510px) and (max-width: 689px) {
          display: none;
        }
        @media (min-width: 290px) and (max-width: 509px) {
          display: none;
        }
      }
    }

    .info {
      margin-top: 85px;
      position: relative;
      z-index: 2;
      // width: 630px;
      width: 50%;
      @media (min-width: 960px) and (max-width: 1229px) {
        margin-top: 45px;
        width: 60%;
      }
      @media (min-width: 690px) and (max-width: 959px) {
        margin-top: 45px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      @media (min-width: 510px) and (max-width: 689px) {
        margin-top: 45px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      @media (min-width: 290px) and (max-width: 509px) {
        margin-top: 25px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      &-irl {
        display: flex;
        justify-content: space-around;
        @media (min-width: 690px) and (max-width: 959px) {
          justify-content: space-between;
          width: 690px;
        }
        @media (min-width: 510px) and (max-width: 689px) {
          justify-content: space-between;
          width: 510px;
        }
        @media (min-width: 290px) and (max-width: 509px) {
          // justify-content: space-between;
          flex-direction: column;
          width: 290px;
        }

        .title {
          p {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 26px;
            @media (min-width: 960px) and (max-width: 1229px) {
              font-size: 20px;
              line-height: 26px;
            }
            @media (min-width: 690px) and (max-width: 959px) {
              font-size: 20px;
              line-height: 26px;
            }
            @media (min-width: 510px) and (max-width: 689px) {
              font-size: 20px;
              line-height: 26px;
            }
            @media (min-width: 290px) and (max-width: 509px) {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }

        .address {
          cursor: pointer;
          margin-top: 25px;
          @media (min-width: 290px) and (max-width: 509px) {
            margin-top: 20px;
          }
          p {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            @media (min-width: 960px) and (max-width: 1229px) {
              height: 41px;
              font-size: 18px;
              line-height: 28px;
            }
            @media (min-width: 690px) and (max-width: 959px) {
              height: 41px;
              font-size: 18px;
              line-height: 28px;
            }
            @media (min-width: 510px) and (max-width: 689px) {
              height: 41px;
              font-size: 16px;
              line-height: 26px;
            }
            @media (min-width: 290px) and (max-width: 509px) {
              height: 41px;
              font-size: 16px;
              line-height: 26px;
            }
          }
        }
        .schedule {
          margin-top: 35px;
          @media (min-width: 290px) and (max-width: 509px) {
            margin-top: 20px;
          }
          p {
            font-size: 18px;
            line-height: 28px;
            @media (min-width: 960px) and (max-width: 1229px) {
              height: 41px;
              font-size: 18px;
              line-height: 28px;
            }
            @media (min-width: 690px) and (max-width: 959px) {
              height: 41px;
              font-size: 18px;
              line-height: 28px;
            }
            @media (min-width: 510px) and (max-width: 689px) {
              height: 41px;
              font-size: 16px;
              line-height: 26px;
            }
            @media (min-width: 290px) and (max-width: 509px) {
              height: 41px;
              font-size: 16px;
              line-height: 26px;
            }
          }
        }
        .tel {
          margin-top: 45px;
          @media (min-width: 290px) and (max-width: 509px) {
            margin-top: 20px;
          }
          &-title p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            @media (min-width: 960px) and (max-width: 1229px) {
              font-size: 18px;
              line-height: 28px;
            }
            @media (min-width: 690px) and (max-width: 959px) {
              font-size: 18px;
              line-height: 28px;
            }
            @media (min-width: 510px) and (max-width: 689px) {
              font-size: 16px;
              line-height: 28px;
            }
            @media (min-width: 290px) and (max-width: 509px) {
              font-size: 16px;
              line-height: 28px;
            }
          }
          &-number {
            p {
              font-weight: 600;
              font-size: 18px;
              line-height: 28px;
              @media (min-width: 960px) and (max-width: 1229px) {
                height: 41px;
                font-size: 18px;
                line-height: 28px;
              }
              @media (min-width: 690px) and (max-width: 959px) {
                height: 41px;
                font-size: 18px;
                line-height: 28px;
              }
              @media (min-width: 510px) and (max-width: 689px) {
                height: 41px;
                font-size: 20px;
                line-height: 28px;
              }
              @media (min-width: 290px) and (max-width: 509px) {
                height: 41px;
                font-size: 20px;
                line-height: 28px;
              }
              span {
                padding-left: 5px;
                position: relative;
                bottom: 2px;
                font-weight: normal;
                font-size: 14px;
                line-height: 14px;
                color: #7C7C7C;
              }
            }
          }
        }
        // .left {}
        .right {
          margin-left: 60px;
          @media (min-width: 960px) and (max-width: 1229px) {
            margin-left: 30px;
          }
          @media (min-width: 690px) and (max-width: 959px) {
            margin-left: 0;
          }
          @media (min-width: 510px) and (max-width: 689px) {
            margin-left: 0;
          }
          @media (min-width: 290px) and (max-width: 509px) {
            margin-left: 0;
          }
        }
      }

      .gray-line {
        width: calc(100% - 20px);
        height: 1px;
        background-color: #EAEAEA;
        margin-top: 45px;
        @media (min-width: 290px) and (max-width: 509px) {
          display: none;
        }
      }

      .info-online {
        display: flex;
        margin-top: 45px;
        @media (min-width: 690px) and (max-width: 959px) {
          width: 690px;
        }
        @media (min-width: 510px) and (max-width: 689px) {
          width: 510px;
        }
        @media (min-width: 290px) and (max-width: 509px) {
          width: 290px;
          flex-direction: column;
          align-items: flex-start;
        }

        .email {
          &-title p {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
          }
          &-text {
            a {
              font-weight: 600;
              font-size: 20px;
              line-height: 28px;
            }
          }
        }

        .inst {
          display: flex;
          margin-left: 97px;
          margin-top: 5px;
          @media (min-width: 510px) and (max-width: 689px) {
            margin-left: 70px;
          }
          @media (min-width: 290px) and (max-width: 509px) {
            margin-top: 30px;
            margin-left: 0;
          }

          &-text p {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #595959;
          }
          &-img {
            margin-left: 22px;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
</style>
