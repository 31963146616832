import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './storages/index'
import VInputmask from 'v-inputmask'
import Notifications from 'vue-notification'

Vue.use(VInputmask)
Vue.use(Notifications)

import VuePictureSwipe from 'vue-picture-swipe'
Vue.component('vue-picture-swipe', VuePictureSwipe)

import './assets/styles/reset.css'

import utilsMix from './components/mixins/utilsMix'

Vue.mixin(utilsMix)

Vue.config.productionTip = false

async function getPageData () {
  var contentful = require('contentful')
  let client = contentful.createClient({
    space: 'i15rfzuxtqbt',
    accessToken: 'RbwdyXJkJ1LQusxUvz0XjAlItH11YUAo5R5SmTNzE1I'
  })
  store.dispatch('setClient', client)
  try {
    let entries = await client.getEntries('2H1AS5ImRvQK6bMCofSpoR')
    // console.log(entries)
    store.dispatch('setEntries', entries)
  } catch (error) {
    console.error('can not get entries ', error)
  }
}

async function initApp () {
  await getPageData()
  new Vue({
    render: h => h(App),
    router,
    store,
  }).$mount('#app')
}

initApp()
