<template>
  <div class="block-4">
    <div class="content">
      <div class="photo">
        <img src="@/assets/img/photo-car.png" alt="">
      </div>
      <div class="delivery">
        <h2>Доставка в любую точку</h2>
        <div class="delivery_text">Любой удобной для Вас транспортной компанией <br>Отгрузка до терминала транспортной компании бесплатная и происходит несколько раз в неделю.</div>
        <div class="delivery_items">
          <div class="item">
            <div class="text">
              <p>Курьерскими службами доставки СДЭК, DHL, SPRS Express, EMS</p>
            </div>
            <div class="image">
              <img src="@/assets/img/truck.svg" alt="">
            </div>
          </div>
          <div class="item">
            <div class="text">
              <p>Индивидуально, указав способ доставки удобный для Вас</p>
            </div>
            <div class="image">
              <img src="@/assets/img/rocket.svg" alt="">
            </div>
          </div>
          <div class="item">
            <div class="text">
              <p>Самостоятельно <br>(самовывоз)</p>
            </div>
            <div class="image">
              <img src="@/assets/img/pickup-face-truck.svg" alt="">
            </div>
          </div>
        </div>
        <div class="orange-line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block-4'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

.block-4 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: $color-gray-background;
  padding-bottom: 60px; // полотступа из след блока

  .content {
    position: relative;
    width: $global-content-width;
    margin-top: 120px;

    .photo {
      position: absolute;
      z-index: 5;
      left: 0;
      top: 50px;
      width: 390px;
      height: 390px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .delivery {
      background-color: #FFFFFF;
      width: 946px;
      height: 516px; // ZZZZ
      margin-left: 289px;
      padding-left: 150px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        text-align: left;
      }

      &_text {
        margin: 45px 0;
        font-weight: 600;
      }
    }

    .delivery_items {
      display: flex;

      .item {
        background-color: $color-light-yellow;
        position: relative;
        width: 235px;
        height: 119px;
        margin-right: 20px;

        .text {
          position: absolute;
          top: 21px;
          left: 25px;
          width: 180px;
          z-index: 5;

          p {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: $color-black-logo;
          }
        }

        .image {
          position: absolute;
          bottom: 7px;
          right: 20px;
          width: 100px;
          height: 100px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  @media (min-width: 960px) and (max-width: 1229px) {
    .content {
      width: $global-content-width-960;

      .photo {
        width: 210px;
        height: 210px;
        top: 35px;
      }

      .delivery {
        margin-left: 80px;
        justify-content: flex-start;
        padding-left: 0;
        width: 881px;
        height: 456px;

        h2 {
          margin-top: 50px;
          padding-left: 160px;
        }

        &_text {
          padding-left: 160px;
        }

        &_items {
          margin-top: 60px;
          padding-left: 80px;
        }
      }
    }
  }
  @media (min-width: 690px) and (max-width: 959px) {
    .content {
      width: $global-content-width-690;

      .photo {
        width: 210px;
        height: 210px;
        top: 35px;
      }

      .delivery {
        margin-left: 30px;
        justify-content: flex-start;
        padding-left: 40px;
        width: 699px;
        height: 475px;

        h2 {
          margin-top: 50px;
          padding-left: 160px;
        }

        &_text {
          padding-left: 160px;
          width: 570px;
        }

        &_items {
          margin-top: 60px;
          padding-left: 0;
          width: 640px;

          .item {
            height: 139px;

            .text {
              left: 21px;
              width: 160px;
            }

            .image {
              bottom: 20px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 510px) and (max-width: 689px) {
    & {
      margin-top: 0;
      padding-bottom: 0;
      background-color: white;
      box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.05);
    }
    .content {
      // width: $global-content-width-510;
      width: auto;
      // min-width: 510px;
      margin-top: 0;

      .photo {
        width: 148px;
        height: 148px;
        top: 44px;
      }

      .orange-line {
        display: none;
      }

      .delivery {
        background-color: transparent;
        margin-left: 0;
        justify-content: flex-start;
        padding-left: 0;
        width: 510px;
        height: 409px;

        h2 {
          margin-top: 44px;
          padding-left: 179px;
        }

        &_text {
          margin-top: 19px;
          padding-left: 179px;
          width: 509px;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          br {
            display: none;
          }
        }

        &_items {
          margin-top: 35px;
          padding-left: 0;
          width: 510px;

          .item {
            width: 160px;
            height: 139px;

            .text {
              left: 21px;
              width: 120px;
              p {
                font-weight: bold;
                font-size: 13px;
                line-height: 19px;
              }
            }

            .image {
              bottom: 20px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 290px) and (max-width: 509px) {
    & {
      margin-top: 0;
      padding-bottom: 40px;
      background-color: white;
      box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.05);
    }
    .content {
      width: auto;
      margin-top: 0;

      .photo {
        width: 290px;
        height: 290px;
        top: 284px;
      }

      .orange-line {
        display: none;
      }

      .delivery {
        background-color: transparent;
        margin-left: 0;
        justify-content: flex-start;
        padding-left: 0;
        width: 290px;
        height: auto;

        h2 {
          margin-top: 54px;
          padding-left: 0;
        }

        &_text {
          margin-top: 19px;
          padding-left: 0;
          width: 290px;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          br {
            display: none;
          }
        }

        &_items {
          margin-top: 342px;
          padding-left: 0;
          width: 290px;
          display: flex;
          flex-direction: column;

          .item {
            width: 290px;
            height: 80px;
            margin-top: 15px;

            .text {
              left: 21px;
              width: 250px;
              p {
                font-weight: bold;
                font-size: 13px;
                line-height: 19px;
              }
            }

            .image {
              bottom: 20px;
              width: 60px;
              height: 60px;
            }
          }
        }
      }
    }
  }
}
</style>