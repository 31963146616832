<template>
  <div class="block-1-utils">
    <div class="content">
      <div class="breadcrums">
        <div class="breadcrums-item" v-for="(item, index) in items" :key="'bread_' + index">
          <div v-if="item.link !== null">
            <router-link :to="item.link">
              <p class="orange-text" @click="clickBreadcrum(item.link)">{{ clearBreadCrumbName(item.name) }}</p>
            </router-link>
          </div>
          <div v-else>
            <p class="orange-text" @click="clickBreadcrum(item.link)">{{ clearBreadCrumbName(item.name) }}</p>
          </div>
        </div>
      </div>
      <div class="left">
        <div class="title">
          <p v-html="bigTitle"></p>
        </div>
        <div class="controls">
          <div class="view-some">
            <p class="orange-text contacts-link" @click="goToBlock('second')">
              <span>{{ viewText }}</span>
              <img src="@/assets/img/download-arrow.png" alt="">
            </p>
          </div>
          <div class="button-wrapper" v-if="showCallBckButton === true">
            <button @click="toggleCallbackPopup()"><p>Обратный звонок</p></button>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="image-wrapper">
          <img :src="getImage(imageLink)" alt="" v-if="clientImage === true">
          <img :src="imageLink" alt="" v-else>
        </div>
        <div class="back-block">
          <div class="orange-line"></div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="callback-popup" v-if="showCallbackPopup">
        <PopupVue @closePopup="toggleCallbackPopup(false)">
          <div class="feed-wrap">
            <FeedBackCallVue :popup="true" :title="'Заявка на обратный звонок'"/>
          </div>
        </PopupVue>
      </div>
    </transition>
  </div>
</template>

<script>
import PopupVue from './PopupVue.vue'
import FeedBackCallVue from '../utils/FeedBackCallVue'
export default {
  props: ['bigTitle', 'viewText', 'showCallBckButton', 'imageLink', 'clientImage'],
  components: { 
    PopupVue,
    FeedBackCallVue
  },
  data () {
    return {
      showCallbackPopup: false,
      items: [
        { name: 'Главная', link: '/' }
      ]
    }
  },
  watch: {
    bigTitle (newVal) {
      this.items = [
        { name: 'Главная', link: '/' },
        { name: newVal, link: null }
      ]
    }
  },
  mounted () {
    this.items.push({name: this.bigTitle, link: null})
  },
  methods: {
    toggleCallbackPopup (val) {
      this.showCallbackPopup = val !== undefined ? val : !this.showCallbackPopup
    },

    clearBreadCrumbName (name) {
      return name.replaceAll('<br>', '')
    },

    clickBreadcrum (linkName) {
      if (linkName !== null && linkName !== undefined) {
        this.$router.push({name: linkName})
      }
    },

    getImage (imgName) {
      let img = require('@/assets/img/' + imgName)
      // console.log(img)
      return img
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.block-1-utils {
  width: 100%;
  height: 770px;
  background-image: url('~@/assets/img/background-photo.png');
  background-repeat: no-repeat;
  background-size: 100% 101%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  @media (min-width: 690px) and (max-width: 959px) {
    background-size: auto;
  }
  @media (min-width: 510px) and (max-width: 689px) {
    background-size: auto;
    height: 718px;
  }
  @media (min-width: 290px) and (max-width: 509px) {
    background-size: auto;
    height: 600px;
  }

  .content {
    width: $global-content-width;
    position: relative;
    @media (min-width: 960px) and (max-width: 1229px) {
      width: $global-content-width-960;
    }
    @media (min-width: 690px) and (max-width: 959px) {
      width: $global-content-width-690;
    }
    @media (min-width: 510px) and (max-width: 689px) {
      width: $global-content-width-510;
    }
    @media (min-width: 290px) and (max-width: 509px) {
      width: $global-content-width-290;
    }

    .breadcrums {
      position: absolute;
      left: 0;
      top: 120px;
      display: flex;
      @media (min-width: 510px) and (max-width: 689px) {
        display: none;
      }
      @media (min-width: 290px) and (max-width: 509px) {
        display: none;
      }

      .breadcrums-item {
        cursor: pointer;
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 106.2%;
        }
      }
      .breadcrums-item p::after { // стрелка после пункта
        content: '>';
        padding: 0 10px;
      }
      .breadcrums-item:last-child p::after { // скрыть стрелку на последнем
        content: ''
      }
      .breadcrums-item:last-child {
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 106.2%;
        }
      }
    }

    .left {
      position: absolute;
      max-width: 52%;
      left: 0;
      top: 271px;
      @media (min-width: 960px) and (max-width: 1229px) {
        max-width: 520px;
      }
      @media (min-width: 690px) and (max-width: 959px) {
        max-width: 520px;
        width: 520px;
        left: calc(50% - 260px);
        top: 170px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @media (min-width: 510px) and (max-width: 689px) {
        max-width: 520px;
        width: 520px;
        left: calc(50% - 260px);
        top: 135px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @media (min-width: 290px) and (max-width: 509px) {
        max-width: initial;
        width: 100%;
        left: 0;
        top: 115px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .title {
        p {
          color: $color-black-logo;
          font-weight: 900;
          font-size: 46px;
          line-height: 68px;
          @media (min-width: 960px) and (max-width: 1229px) {
            font-size: 40px;
            line-height: 46px;
          }
          @media (min-width: 690px) and (max-width: 959px) {
            font-size: 36px;
            line-height: 44px;
            text-align: center;
          }
          @media (min-width: 510px) and (max-width: 689px) {
            font-size: 28px;
            line-height: 34px;
            text-align: center;
          }
          @media (min-width: 290px) and (max-width: 509px) {
            font-size: 22px;
            line-height: 26px;
            text-align: center;
          }
        }
      }
      .controls {
        display: flex;
        margin-top: 55px;
        @media (min-width: 690px) and (max-width: 959px) {
          margin-top: 45px;
        }
        @media (min-width: 510px) and (max-width: 689px) {
          margin-top: 45px;
        }
        @media (min-width: 290px) and (max-width: 509px) {
          margin-top: 25px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .view-some {
          height: 52px;
          display: flex;
          align-items: center;
          p {
            font-weight: bold;
            font-size: 13px;
            text-transform: uppercase;
            span {
              color: $color-orange-logo;
              border-bottom: 1px dashed $color-orange-logo;
              transition: all 0.3s;
            }
            img {
              margin-left: 3px;
              height: 12px;
            }
          }
          p:hover {
            cursor: pointer;
            span {
              border-bottom: 1px dashed transparent;
            }
          }
        }
        .button-wrapper {
          margin-left: 45px;
          @media (min-width: 290px) and (max-width: 509px) {
            margin-left: 0;
            margin-top: 17px;
          }
          button p {
            padding: inherit 45px;
            @media (min-width: 960px) and (max-width: 1229px) {
              padding-left: 20px;
              padding-right: 20px;
            }
            @media (min-width: 690px) and (max-width: 959px) {
              padding-left: 20px;
              padding-right: 20px;
            }
            @media (min-width: 510px) and (max-width: 689px) {
              padding-left: 20px;
              padding-right: 20px;
            }
            @media (min-width: 290px) and (max-width: 509px) {
              padding-left: 20px;
              padding-right: 20px;
            }
          }
        }
      }
    }

    .right {
      .image-wrapper {
        width: 420px;
        height: 330px;
        position: absolute;
        right: 180px;
        top: 220px;
        z-index: 5;
        @media (min-width: 960px) and (max-width: 1229px) {
          width: 330px;
          right: 40px;
        }
        @media (min-width: 690px) and (max-width: 959px) {
          width: 330px;
          right: 40px;
          top: initial;
          bottom: 24px;
          left: calc(50% - 165px);
        }
        @media (min-width: 510px) and (max-width: 689px) {
          width: 330px;
          right: 40px;
          top: initial;
          bottom: 24px;
          left: calc(50% - 165px);
        }
        @media (min-width: 290px) and (max-width: 509px) {
          width: 290px;
          right: 40px;
          top: initial;
          bottom: 24px;
          left: calc(50% - 145px);
        }
        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .back-block {
        background-color: #F8F8F8;
        opacity: 0.9;
        position: absolute;
        left: 750px;
        top: 166px;
        width: 100vw;
        height: 440px;
        @media (min-width: 690px) and (max-width: 959px) {
          width: 450px;
          height: 150px;
          top: initial;
          bottom: 0;
          left: calc(50% - 225px);
        }
        @media (min-width: 510px) and (max-width: 689px) {
          width: 450px;
          height: 150px;
          top: initial;
          bottom: 0;
          left: calc(50% - 225px);
        }
        @media (min-width: 290px) and (max-width: 509px) {
          width: 450px;
          height: 150px;
          top: initial;
          bottom: 0;
          left: calc(50% - 225px);
        }
        .orange-line {
          @media (min-width: 690px) and (max-width: 959px) {
            left: 0;
            top: 0;
            width: 100%;
            height: 5px;
          }
          @media (min-width: 510px) and (max-width: 689px) {
            left: 0;
            top: 0;
            width: 100%;
            height: 5px;
          }
          @media (min-width: 290px) and (max-width: 509px) {
            left: 0;
            top: 0;
            width: 100%;
            height: 5px;
          }
        }
      }
    }
  }
}
.callback-popup {
  z-index: 10;
}

.contacts-link {
  display: flex;
  align-items: center;
}
</style>
