<template>
  <div class="contacts-component">
    <div class="header-wrapper">
      <HeaderVue/>
    </div>
    <Block1Vue
      :bigTitle="'Контактная <br>информация'"
      :viewText="'посмотреть контакты'"
      :showCallBckButton="true"
      :imageLink="'zavodik.jpg'"
      :clientImage="true"
    />
    <MapContactsVue/>
    <AboutVue/>
    <Block2Vue/>
    <PartnersVue/>
    <FooterVue/>
  </div>
</template>

<script>
import HeaderVue from '@/components/common/HeaderVue.vue'
import Block1Vue from '@/components/utils/Block1Vue'
import MapContactsVue from '@/components/contacts/MapContactsVue'
import AboutVue from '@/components/main/AboutVue'
import Block2Vue from '../main/Block2Vue.vue'
import PartnersVue from '../main/PartnersVue.vue'
import FooterVue from '../common/FooterVue.vue'

export default {
  components: {
    Block1Vue,
    HeaderVue,
    MapContactsVue,
    AboutVue,
    Block2Vue,
    PartnersVue,
    FooterVue
  },

  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$store.dispatch('setValue', {key: 'loadApp', value: false})
      }, 300)
    })
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
.header-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 7;
}
.contacts-component {
  width: 100%;
  overflow-x: hidden;
}
</style>