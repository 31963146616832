<template>
  <div class="block-2">
    <div class="content">
      <div class="title" v-if="showTitle !== false">
        <h1>{{title === undefined ? 'Наши услуги' : title}}</h1>
      </div>
      <div class="services">

        <router-link to="/service/abz">
          <div class="services_item" :class="{'services_item-active': currentRoute === '/service/abz'}" @click="emitClosePopup()">
            <div class="services_item_image">
              <img src="@/assets/img/item-factory.svg" alt="">
            </div>
            <div class="services_item_text">
              <p>Запчасти <br>к асфальто-бетонным заводам</p>
              <!-- показывается без наведения -->
              <div class="orange-line"></div>
            </div>
            <!-- показывается при наведении -->
            <div class="orange-line orange-line_item"></div>
          </div>
        </router-link>

        <router-link to="/service/metal-processing">
          <div class="services_item" :class="{'services_item-active': currentRoute === '/service/metal-processing'}" @click="emitClosePopup()">
            <div class="services_item_image">
              <img src="@/assets/img/item-lathe.svg" alt="">
            </div>
            <div class="services_item_text">
              <p>Механическая <br>обработка металла</p>
              <div class="orange-line"></div>
            </div>
            <!-- показывается при наведении -->
            <div class="orange-line orange-line_item"></div>
          </div>
        </router-link>

        <router-link to="/service/groove-of-bandages">
          <div class="services_item" @click="emitClosePopup()">
            <div class="services_item_image">
              <img src="@/assets/img/item-sb.svg" alt="">
            </div>
            <div class="services_item_text">
              <p>Проточка бандажей и катков сушильного барабана</p>
              <div class="orange-line"></div>
            </div>
            <!-- показывается при наведении -->
            <div class="orange-line orange-line_item"></div>
          </div>
        </router-link>

        <router-link to="/service/welding-of-metal-structures">
          <div class="services_item" :class="{'services_item-active': currentRoute === '/service/welding-of-metal-structures'}" @click="emitClosePopup()">
            <div class="services_item_image">
              <img src="@/assets/img/item-welding.svg" alt="">
            </div>
            <div class="services_item_text">
              <p>Сварка <br>металлоконструкций</p>
              <div class="orange-line"></div>
            </div>
            <!-- показывается при наведении -->
            <div class="orange-line orange-line_item"></div>
          </div>
        </router-link>

        <router-link to="/service/road-construction-machinery">
          <div class="services_item" :class="{'services_item-active': currentRoute === '/service/road-construction-machinery'}" @click="emitClosePopup()">
            <div class="services_item_image">
              <img src="@/assets/img/item-excavator.svg" alt="">
            </div>
            <div class="services_item_text">
              <p>Запчасти к дробилкам и дорожно-строительной технике</p>
              <div class="orange-line"></div>
            </div>
            <!-- показывается при наведении -->
            <div class="orange-line orange-line_item"></div>
          </div>
        </router-link>

        <router-link to="/service/other">
          <div class="services_item" :class="{'services_item-active': currentRoute === '/service/other'}" @click="emitClosePopup()">
            <div class="services_item_image">
              <img src="@/assets/img/item-file.svg" alt="">
            </div>
            <div class="services_item_text">
              <p>Показать другие <br>услуги</p>
              <div class="orange-line"></div>
            </div>
            <!-- показывается при наведении -->
            <div class="orange-line orange-line_item"></div>
          </div>
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block-2',
  props: ['showTitle', 'title'],
  computed: {
    currentRoute () {
      return this.$route.path
    }
  },
  methods: {
    emitClosePopup () {
      this.$emit('closeMenuFromHeader')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

.block-2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: $color-gray-background;

  .content {
    width: $global-content-width;
    padding-bottom: 60px; // половина отсутпа до третьего блока

    .title {
      margin-top: 100px;
      width: 100%;
    }

    .services {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &_item {
        width: 390px;
        // height: 216px;
        background-color: white;
        margin-top: 30px;
        box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        padding: 25px 25px;
        transition: all 0.3s;
        position: relative;

        &_image {
          width: 64px;
          height: 64px;
        }

        &_text {
          margin-top: 30px;
          position: relative;
          p {
            transition: all 0.3s;
            padding-left: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
          }

          .orange-line {
            transition: all 0.3s;
            width: 5px;
          }
        }

        .orange-line_item {
          transition: all 0.3s;
          width: 0;
        }

      }

      &_item:hover {
        cursor: pointer;
        background-color: $color-light-yellow;

        .services_item_text {
          p {
            transition: all 0.3s;
            padding-left: 0;
          }
          .orange-line {
            transition: all 0.3s;
            width: 0;
            opacity: 0;
          }
        }

        .orange-line_item {
          width: 6px;
        }
      }

      &_item-active {
        background-color: $color-light-yellow;

        .services_item_text {
          p {
            transition: all 0.3s;
            padding-left: 0;
          }
          .orange-line {
            transition: all 0.3s;
            width: 0;
            opacity: 0;
          }
        }
        .orange-line_item {
          width: 6px;
        }
      }
    }
  }
  @media (min-width: 960px) and (max-width: 1229px) {
    .content {
      width: $global-content-width-960;

      .services {
        &_item {
          width: 450px;
        }
      }
    }
  }
  @media (min-width: 690px) and (max-width: 959px) {
    .content {
      width: $global-content-width-690;
      padding-bottom: 45px;

      .services {
        &_item {
          width: 330px;

          &_text {
            p {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 510px) and (max-width: 689px) {
    .content {
      width: $global-content-width-510;
      padding-bottom: 40px;

      .title {
        margin-top: 80px;
      }

      .services {
        margin-top: 10px;
        &_item {
          width: 240px;

          &_text {
            p {
              font-weight: bold;
              font-size: 14px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 290px) and (max-width: 509px) {
    .content {
      width: $global-content-width-290;
      padding-bottom: 40px;

      .title {
        margin-top: 60px;
      }

      .services {
        margin-top: 0;

        a {
          width: 100%;
        }

        &_item {
          width: 100%;
          height: 77px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 18px 25px 18px 15px;

          &_image {
            width: 40px;
            height: 40px;
            flex: none;
          }

          &_text {
            margin-top: 0;
            width: 190px;

            p {
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 19px;
              br {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
