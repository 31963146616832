<template>
  <div class="header">
    <div class="content">
      <div class="left">
        <div class="logo">
          <router-link to="/">
            <img src="@/assets/img/logo.png" alt="">
          </router-link>
        </div>
        <div class="ten-years">
          <img src="@/assets/img/ten-years.svg" alt="">
          <p class="ten-years_text">10 лет на рынке</p>
        </div>
      </div>

      <div class="right">
        <div class="menu-buttons">
          <div class="services" :class="{'services-open': menuOpen}" @click="toggleMenu()">
            <a href="javascript:void(0)">Услуги</a>
          </div>
          <div class="contacts">
            <router-link to="/contacts">
              <p class="hover-text">
                Контакты
              </p>
            </router-link>
          </div>
        </div>
        <div class="location">
          <div class="location-icon">
            <img src="@/assets/img/location.svg" alt="" @click="goToBlock('map')">
          </div>
          <div class="location-text" @click="goToBlock('map')">г. Воронеж, ул. <br>Красный Октябрь, 2Б</div>
        </div>
        <div class="telephone">
          <a href="tel:+74732572951"><p class="orange-text">+7 (473) 257-29-51</p></a>
        </div>
      </div>
      <div class="mobile-menu" @click="toggleMenu()">
        <img src="@/assets/img/menu.svg" alt="">
      </div>
      <div class="down-line"></div>

      <!-- выпадающее меню -->
      <!-- TODO Добавить click-outside -->
      <transition name="fade">
        <div class="menu" v-show="menuOpen">
          <div class="menu-1230">
            <block2Vue :showTitle="false" @closeMenuFromHeader="toggleMenu()"/>
          </div>

          <div class="others-less1230">
            <div class="from-header">
              <div class="adress" @click="goToBlock('map'), toggleMenu()">
                <div class="adress-img">
                  <img src="@/assets/img/location.svg" alt="">
                </div>
                <p>г. Воронеж, ул.<br>Красный Октябрь, 2Б</p>
              </div>
              <div class="phone"><a href="tel:+74732572951"><p class="orange-text">+7 (473) 257-29-51</p></a></div>
              <div class="buttons-block">
                <div class="contacts">
                  <router-link to="/contacts">
                    <p>КОНТАКТЫ</p>
                  </router-link>
                </div>
                <div class="services" :class="{'services-open': servicesOpen, 'services-arrow-open-animation': servicesOpen}" @click="toggleServices()">
                  <p>УСЛУГИ</p>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M506.157,132.386c-7.803-7.819-20.465-7.831-28.285-0.029l-207.73,207.299c-7.799,7.798-20.486,7.797-28.299-0.015
                        L34.128,132.357c-7.819-7.803-20.481-7.79-28.285,0.029c-7.802,7.819-7.789,20.482,0.029,28.284l207.701,207.27
                        c11.701,11.699,27.066,17.547,42.433,17.547c15.358,0,30.719-5.846,42.405-17.533L506.128,160.67
                        C513.946,152.868,513.959,140.205,506.157,132.386z"/>
                    </g>
                  </g>
                  </svg>
                </div>
              </div>
              <div class="orange-line"/>
            </div>

            <transition name="fade-height">
              <div class="navigation" v-show="servicesOpen">
                <div class="scroll-wrapper">
                  <div class="col">
                    <router-link to="/service/abz">
                      <div class="nav-item" @click="toggleMenu()"><p>Запчасти <br>к асфальто-бетонным заводам</p></div>
                    </router-link>

                    <router-link to="/service/metal-processing">
                      <div class="nav-item" @click="toggleMenu()"><p>Механическая <br>обработка металла</p></div>
                    </router-link>

                    <router-link to="/service/groove-of-bandages">
                      <div class="nav-item" @click="toggleMenu()"><p>Проточка бандажей и катков сушильного барабана</p></div>
                    </router-link>

                    <router-link to="/service/welding-of-metal-structures">
                      <div class="nav-item" @click="toggleMenu()"><p>Сварка <br>металлоконструкций</p></div>
                    </router-link>
                  </div>
                  <div class="col">
                    <router-link to="/service/road-construction-machinery">
                      <div class="nav-item" @click="toggleMenu()"><p>Запчасти к дробилкам и дорожно-строительной технике</p></div>
                    </router-link>

                    <router-link to="/service/other">
                      <div class="nav-item" @click="toggleMenu()"><p>Аренда<br> территорий и помещений</p></div>
                    </router-link>

                    <router-link to="/service/other">
                      <div class="nav-item" @click="toggleMenu()"><p>Аренда оборудования</p></div>
                    </router-link>

                  </div>
                </div>
                <div class="orange-line"/>
              </div>
            </transition>

          </div>
          <div class="close-wrapper" @click="toggleMenu()">
            <img src="@/assets/img/close-mobile.svg" alt="">
          </div>

        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Block2Vue from '../main/Block2Vue.vue'
export default {
  components: { Block2Vue },
  name: 'header-component-vue',
  data () {
    return {
      menuOpen: false,
      servicesOpen: false
    }
  },
  methods: {
    toggleMenu () {
      this.menuOpen = !this.menuOpen
    },
    toggleServices () {
      this.servicesOpen = !this.servicesOpen
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/styles/variables.scss";
$left-block-width: 42.6%;
$left-block-width-960: 53%;
$left-block-width-690: 55%;
$left-block-width-510: 55%;

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.fade-height-enter-active,
.fade-height-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}
.fade-height-enter,
.fade-height-leave-to
{
  opacity: 0;
  max-height: 0px;
}

.header {
  width: 100%;
  padding-left: 4px;
  display: flex;
  justify-content: center;
  height: 86px;

  .content {
    position: relative;
    display: flex;
    width: $global-content-width;
    // @media (min-width: 1230px) {
    //   width: $global-content-width;
    // }
    @media (min-width: 960px) and (max-width: 1229px) {
      width: $global-content-width-960;
    }
    @media (min-width: 690px) and (max-width: 959px) {
      width: $global-content-width-690;
    }
    @media (min-width: 510px) and (max-width: 689px) {
      width: $global-content-width-510;
    }
    @media (min-width: 290px) and (max-width: 509px) {
      width: $global-content-width-290;
    }
  }

  .left {
    width: $left-block-width;
    @media (min-width: 960px) and (max-width: 1229px) {
      width: $left-block-width-960;
    }
    @media (min-width: 690px) and (max-width: 959px) {
      width: $left-block-width-690;
    }
    @media (min-width: 510px) and (max-width: 689px) {
      width: $left-block-width-510;
    }
    @media (min-width: 290px) and (max-width: 509px) {
      width: 100%;
      justify-content: space-between;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding-left: $global-padding-x;

    .logo {
      width: 211px;
      height: 54px;
      @media (min-width: 510px) and (max-width: 689px) {
        width: 150px;
      }
      @media (min-width: 290px) and (max-width: 509px) {
        justify-self: flex-start;
        width: 200px;
        display: flex;
        // justify-content: center;
        align-items: center;
      }

      img {
        object-fit: cover;
      }
    }

    .ten-years {
      margin-left: 40px;
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      @media (min-width: 690px) and (max-width: 959px) {
        margin-left: 20px;
      }
      @media (min-width: 510px) and (max-width: 689px) {
        p {
          font-size: 13px;
        }
        margin-left: 15px;
        img {
          width: 30px;
        }
      }
      @media (min-width: 290px) and (max-width: 509px) {
        display: none;
        p {
          font-size: 13px;
        }
        margin-left: 15px;
        img {
          width: 30px;
        }
      }

      &_text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: $color-text;
        margin-left: 10px;
        width: 65px;
      }
    }
  }

  .right {
    width: calc(100% - #{$left-block-width});
    @media (min-width: 960px) and (max-width: 1229px) {
      width: calc(100% - #{$left-block-width-960});
    }
    @media (min-width: 690px) and (max-width: 959px) {
      width: calc(100% - #{$left-block-width-690});
    }
    @media (min-width: 510px) and (max-width: 689px) {
      width: calc(100% - #{$left-block-width-510});
    }
    @media (min-width: 290px) and (max-width: 509px) {
      // width: calc(100% - #{$left-block-width-510});
      display: none;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-right: $global-padding-x;

    .menu-buttons {
      display: flex;
      justify-content: space-between;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      // @media (min-width: 1230px) {
      //   width: $global-content-width;
      // }
      @media (min-width: 960px) and (max-width: 1229px) {
        display: none;
      }
      @media (min-width: 690px) and (max-width: 959px) {
        display: none;
      }
      @media (min-width: 510px) and (max-width: 689px) {
        display: none;
      }
      @media (min-width: 290px) and (max-width: 509px) {
        display: none;
      }

      .contacts {
        margin-left: 30px;
        min-height: 44px;
        padding: 0 5px;
        display: flex;
        align-items: center;
      }
      .services {
        position: relative;
        min-height: 44px;
        text-transform: uppercase;
        padding: 0 5px;
        display: flex;
        align-items: center;
        z-index: 55;
        a {
          position: relative;
          display: inline-block;
          transition: 0.3s;
          &:after {
            position: absolute;
            content: "";
            top: 1px;
            right: -17px;
            width: 10px;
            height: 10px;
            border: 2px solid #595959;
            border-top: none;
            border-left: none;
            transform: rotate(45deg);
            transition: .3s;
          }
        }
        &:hover a {
          color: #FF4300;
          &:after {
            border-color: #FF4300;
          }
        }
        &.services-open {
          a {
            color: $color-orange-logo;
            box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.05);
            background-color: #F6F6F6;
            &:after {
              top: 6px;
              transform: rotate(-135deg);
              border-color: #FF4300;
            }
          }
        }
      }
      .hover-text {
        text-transform: uppercase;
      }
    }

    .location {
      display: flex;
      align-items: center;
      cursor: pointer;

      @media (min-width: 690px) and (max-width: 959px) {
        display: none;
      }
      @media (min-width: 510px) and (max-width: 689px) {
        display: none;
      }
      @media (min-width: 290px) and (max-width: 509px) {
        display: none;
      }

      &-text {
        margin-left: 10px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
      }
    }

    .telephone {
      font-weight: 900;
      font-size: 24px;
      line-height: 106.2%;
      @media (min-width: 510px) and (max-width: 689px) {
        font-size: 18px;
      }
      @media (min-width: 290px) and (max-width: 509px) {
        display: none;
      }
    }
  }
}

.down-line {
  position: absolute;
  bottom: 0;
  width: calc(#{$global-content-width} - 10px);
  height: 1px;
  background-color: #ECECEC;
  // @media (min-width: 1230px) {
  //   width: calc(#{$global-content-width} - 10px);
  // }
  @media (min-width: 960px) and (max-width: 1229px) {
    width: $global-content-width-960;
  }
  @media (min-width: 690px) and (max-width: 959px) {
    width: calc(#{$global-content-width-690} - 10px);
  }
  @media (min-width: 510px) and (max-width: 689px) {
    width: calc(#{$global-content-width-510} - 10px);
  }
  @media (min-width: 290px) and (max-width: 509px) {
    width: calc(#{$global-content-width-290} - 10px);
  }
}

.mobile-menu {
  display: none;
  @media (min-width: 960px) and (max-width: 1229px) {
    display: block;
    margin-top: 38px;
    margin-right: 15px;
    margin-left: 15px;
  }
  @media (min-width: 690px) and (max-width: 959px) {
    display: block;
    margin-top: 38px;
    margin-left: -35px;
  }
  @media (min-width: 510px) and (max-width: 689px) {
    display: block;
    margin-top: 38px;
    margin-left: -35px;
  }
  @media (min-width: 290px) and (max-width: 509px) {
    display: block;
    margin-top: 39px;
    margin-left: -34px;
  }
}

// открывающееся меню
.menu {
  width: calc(100% - 6px);
  // width: 100%;
  position: absolute;
  z-index: 7;
  top: 65px;
  left: 0;
  @media (min-width: 960px) and (max-width: 1229px) {
    width: auto;
    height: auto;
    right: 0;
    left: initial;
  }
  @media (min-width: 690px) and (max-width: 959px) {
    width: auto;
    height: auto;
    right: 0;
    left: initial;
  }
  @media (min-width: 510px) and (max-width: 689px) {
    width: auto;
    height: auto;
    right: 0;
    left: initial;
  }
  @media (min-width: 290px) and (max-width: 509px) {
    width: auto;
    height: auto;
    right: 0;
    left: initial;
  }

  .close-wrapper {
    display: none;
    @media (min-width: 290px) and (max-width: 1229px) {
      position: absolute;
      right: 0;
      top: -40px;
      width: 50px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.05);
    }
  }

  .menu-1230 {
    @media (min-width: 960px) and (max-width: 1229px) {
      display: none;
    }
    @media (min-width: 690px) and (max-width: 959px) {
      display: none;
    }
    @media (min-width: 510px) and (max-width: 689px) {
      display: none;
    }
    @media (min-width: 290px) and (max-width: 509px) {
      display: none;
    }
  }
  .others-less1230 {
    display: none!important;
    background-color: transparent;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    z-index: 8;
    @media (min-width: 960px) and (max-width: 1229px) {
      display: flex!important;
    }
    @media (min-width: 690px) and (max-width: 959px) {
      display: flex!important;
    }
    @media (min-width: 510px) and (max-width: 689px) {
      display: block!important;
    }
    @media (min-width: 290px) and (max-width: 509px) {
      display: block!important;
    }

    .from-header {
      padding: 22px 26px 10px 35px;
      box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.05);
      width: fit-content;
      background: #FFFFFF;
      @media (min-width: 690px) and (max-width: 959px) {
        display: flex;
      }
      @media (min-width: 510px) and (max-width: 689px) {
        display: flex;
        width: 365px;
      }
      @media (min-width: 290px) and (max-width: 509px) {
        width: 290px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .adress {
        @media (min-width: 960px) and (max-width: 1229px) {
          display: none;
        }
        @media (min-width: 690px) and (max-width: 959px) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-right: 53px;
        }
        @media (min-width: 510px) and (max-width: 689px) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-right: 53px;
        }
        @media (min-width: 290px) and (max-width: 509px) {
          display: flex;
          justify-content: space-between;

        }
        p {
          margin-top: 11px;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          @media (min-width: 290px) and (max-width: 509px) {
            margin-top: 0;
          }
        }

        &-img {
          @media (min-width: 290px) and (max-width: 509px) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 11px;
          }
        }
      }
      .phone {
        @media (min-width: 960px) and (max-width: 1229px) {
          display: none;
        }
        @media (min-width: 690px) and (max-width: 959px) {
          display: none;
        }
        @media (min-width: 510px) and (max-width: 689px) {
          display: none;
        }
        @media (min-width: 290px) and (max-width: 509px) {
          display: block;
          width: 100%;
          margin-top: 16px;
          p {
            width: 100%;
            text-align: center;
            font-weight: 900;
            font-size: 20px;
            line-height: 106.2%;
          }
        }
      }
      .buttons-block {
        .contacts {
          @media (min-width: 290px) and (max-width: 509px) {
            margin-top: 25px;
          }
          p {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 18px;
            text-transform: uppercase;
          }
        }
        .services-arrow-open-animation {
          svg {
            transform: rotate(180deg);
          }
        }
        .services {
          min-height: 44px;
          padding: 0 5px;
          display: flex;
          align-items: center;
          position: relative;
          z-index: 55;
          margin-top: 15px;
          svg {
            transition: transform 0.3s;
            width: 20px;
            margin-left: 10px;
          }
          p {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 18px;
            text-transform: uppercase;
          }
        }
        .services-open {
          box-shadow: none;
        }
      }
    }

    .navigation {
      margin-top: -1px;
      margin-right: 6px;
    }
    .scroll-wrapper {
      background-color: #F7F7F7;
      position: relative;
      z-index: 7;
      box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.05);
      padding: 30px 36px 40px 36px;
      display: flex;
      overflow: hidden;
      @media (min-width: 510px) and (max-width: 689px) {
        flex-direction: column;
        height: 338px;
        overflow-y: auto;
        // margin-right: 6px;
        width: 359px;
      }
      @media (min-width: 290px) and (max-width: 509px) {
        flex-direction: column;
        height: 338px;
        overflow-y: auto;
        // margin-right: 6px;
        width: 290px;
        padding: 21px 0 22px 17px;
      }
      .col {
        .nav-item {
          // padding: 12px 0 20px 0;
          height: 80px;
          width: 275px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #E0E0E0;
          @media (min-width: 290px) and (max-width: 509px) {
            width: 100%;
          }
          p {
            transition: color 0.3s;
            color: #7C7C7C;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            @media (min-width: 290px) and (max-width: 509px) {
              width: 100%;
            }
          }
          p:hover {
            color: #2C2C2C;
          }
        }
      }
      .col:nth-child(1) {
        margin-right: 42px;
      }
    }

    .orange-line {
      left: initial;
      right: 0;
    }
  }
}
</style>
