import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    entries: null,
    client: null,
    loadApp: true
  },

  mutations: {
    setEntries (state, entries) {
      state.entries = entries
    },
    setClient (state, client) {
      state.client = client
    },
    setValue (state, keyVal) {
      console.log(keyVal)
      state[keyVal.key] = keyVal.value
    },
  },

  actions: {
    setEntries ({commit}, entries) {
      commit('setEntries', entries)
    },
    setClient ({commit}, client) {
      commit('setClient', client)
    },
    setValue ({commit}, keyVal) {
      commit('setValue', keyVal)
    }
  },

  getters: {
    value: state => key => {
      return state[key]
    },


    entry: state => entryTag => {

      let snakeToCamel = (str) => str.replace(
        /([-_][a-z])/g,
        (group) => group.toUpperCase()
                        .replace('-', '')
                        .replace('_', '')
      )
      let entryTagCamel = snakeToCamel(entryTag)
      let entry = state.entries.items.find(entryItem => {
        let hasTag = entryItem.metadata.tags.findIndex(tag => {
          return tag.sys.id === entryTagCamel
        })
        return hasTag !== -1
      })
      return entry
    }
  }
})

export default store