<template>
  <div class="callback">
    <div class="callback-title">
      <p>
        Заявка на обратный звонок
      </p>
    </div>
    <div class="callback-form">
      <input type="text" placeholder="Имя" v-model="name">
      <input type="text" placeholder="Номер телефона" class="ml20" v-model="phone" v-inputmask="'+7 (999)-999-99-99'">
      <input type="text" placeholder="Email" class="mt20 mt20" v-model="email">
      <button @click="send()"><p>отправить</p></button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'call-back',
  data () {
    return {
      name: '',
      phone: '',
      email: ''
    }
  },
  methods: {
    send () {
      let url = process.env.VUE_APP_HOST + '/mail'
      let data = {
        name: this.name,
        phone: this.phone,
        email: this.email
      }
      axios.post(url, data).then(response => {
        console.log(response)
        this.$notify({
          group: 'foo',
          title: 'Сообщение отправлено',
          text: 'Мы свяжемся с Вами в ближайшее время'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.callback {
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 495px;
  background-color: #FFFFFF;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 290px) and (max-width: 509px) {
    width: 100vw;
    height: 100vh;
    max-width: initial;
    max-height: initial;
    padding: 0;
  }

  &-title {
    width: 100%;
    text-align: center;

    p {
      font-weight: bold;
      font-size: 24px;
      line-height: 160%;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 17px;

    input {
      margin-top: 20px;
    }

    button {
      margin-top: 30px;
    }
  }
}
</style>
